 a {
   text-decoration: none !important
 }


 .App {
   text-align: center;
 }

 .App-logo {
   height: 40vmin;
   pointer-events: none;
 }

 @media (prefers-reduced-motion: no-preference) {
   .App-logo {
     animation: App-logo-spin infinite 20s linear;
   }
 }

 .App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
 }

 .App-link {
   color: #61dafb;
 }

/* .pagination{
  background-color: #61dafb;
} */
.pagination {
  display: inline-block;
}
.pagination button{
  border:none; 
  outline:none;
}
.paginationn{
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
}

.active-btn {
  background-color:#FFC900;
}

 @keyframes App-logo-spin {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }

 .login_container {
   margin-top: 10%;
 }

 .pwd-part h5 {
   cursor: pointer;
 }

 .carousel-status,
 .thumbs-wrapper {
   display: none;
 }


 .verifyphone-part .sign_up_ {
   color: #707070;
   font-weight: 400;
 }



 .label_ {
   font-weight: 300;
   font-size: 12px;
   line-height: 16px;
   color: #707070;
   margin-left: -200px !important;
 }

 .button_otp_div {
   margin-top: 35px;
   text-align: center;
 }

 .button_login_div {
   text-align: center;

 }

 .button_login_div>button {
   background: #FFC900;
   box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
   border-radius: 41px;
   width: 358px;
   height: 40px;
   border: none;
   font-weight: 600;
   font-size: 14px;
   text-align: center;
   margin-top: 10px;
   color: #000000;
 }

 .sign_up_with {
   margin: 10px 0;
   text-align: center;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   color: #000000;
 }

 .choose_business {
   display: flex;
   justify-content: center;

 }

 .choose_business button:hover {
   border: 1px solid #FFC900;
   color: #FFC900;
   font-weight: 600;
 }


 .choose_business_active {
   border: 1px solid #FFC900 !important;
   color: #FFFFFF !important;
   font-weight: 600;
   background-color: #FFC900 !important;
 }

 .forget_password {
   margin-top: 15px;
   text-align: right;
   color: #FFC900 !important;
 }

 .send_otp {
   font-size: 10px;
   text-align: right;
   max-width: 280px;
   margin: 15px auto 30px;
 }

 .send_otp a {
   color: #FFC900 !important;
   font-size: 12px;
   font-weight: 600;
 }

 .forget_password a {
   font-size: 14px;
   color: #FFC900 !important;
 }

 .verify_logo {
   display: flex;
   justify-content: center;
 }

 .verify_logo div button span {
   padding-right: 10px;
 }

 .verify_logo button {
   background-color: white;
   color: #6F6F6F;
   font-size: 12px;
   text-align: center;
   background: #FBFBF9;
   border: 1px solid #DBDBDB;
   border-radius: 40px;
   width: 358px;
   height: 40px;
   font-weight: 600;
 }

 .otp_ {
   padding-bottom: 3px;
   border-bottom: 1px solid black;
 }



 .otp_container>div>div+div {
   margin-left: 20px;
 }

 .otp_container>div input {
   justify-content: center;
   margin-left: 20px;
   background: #FFFFFF !important;
   border: 1px solid rgba(225, 225, 225, 0.7);
   border-radius: 12px;
   width: 56px !important;
   height: 56px !important;
   outline-color: #FFC900;
 }

 .otp_container>div .veryfy_code {
   display: flex;
   justify-content: center;

 }

 .veryfy_code input {
   margin: 20px;
   width: 56px;
   height: 56px;
   border: 1px solid rgba(225, 225, 225, 0.7);
   border-radius: 12px;
   padding-left: 22px;
   outline-color: #FFC900;

 }

 .popup_cross {
   cursor: pointer;
   position: absolute;
   right: 25px;
   top: 25px;
   background-color: #fff;
 }

 .detail_class div {
   font-size: 16px;
 }

 .tab_button {
   display: flex;
 }

 .very_email {
   color: #707070;
   font-size: 12px;
 }

 .popup-content {
   width: 90% !important;
   max-width: 502px;
   padding: 50px !important;
   border-radius: 20px !important;
 }

 .popup-content h4 {
   font-size: 24px;
   color: #000;
   font-weight: 600;
 }


 .popup-content p {
   font-size: 12px;
   color: #707070;
   font-weight: 400;
 }

 .very_email_input {
   margin: 30px 0;
 }

 .very_email_input input {
   outline: none;
   border: none;
   width: 100%;
   background: #eee;
   display: block;
   font-size: 16px;
   color: #000;
   border-radius: 5px;
   padding: 10px;
 }

 .very_email_input input:placeholder {
   color: #C7C7C7;
 }

 .active_DELIVERED+li{
  display: none !important;
}
 .email_next_button {
   background: #FFC900;
   box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
   border-radius: 41px;
   width: 100%;
   height: 40px;
   border: none;
   font-weight: 400;
   font-size: 14px;
   text-align: center;
   margin-top: 10px;
   color: #000000;
 }

 .tab_button button {
   margin: 10px;
   border-radius: 3px;
   font-size: 14px;
   background-color: #FFC900;
   width: 144px;
   height: 31px;
   border: #FFC900;
   font-weight: 600;
 }

 @media (min-width: 767px) and (max-width: 936px) {
   .input_filed input {
     width: 290px;
   }

   .button_otp {
     width: 290px !important;
   }

   .verify_logo div button {
     width: 290px !important;
   }

   .forget_password {
     margin-left: 150px;
   }

 }



 @media (min-width: 320px) and (max-width: 392px) {
   .input_filed input {
     width: 275px;
   }


   .button_otp {
     width: 275px !important;
   }

   .verify_logo div button {
     width: 275px !important;
   }

   .forget_password {
     margin-left: 130px;
   }


 }

 .tab_button button {
   margin: 10px;
   border-radius: 3px;
   font-size: 14px;
   background-color: #FFC900;
   width: 144px;
   height: 31px;
   border: #FFC900;
   font-weight: 600;
 }


 @media(max-width:576.50px) {


   .popup-content {
     padding: 30px !important;
   }
 }

 .sign_up_loader-content {
   background-color: transparent !important;
   border: none !important;
   width: 100% !important;
   max-width: 100% !important;
   padding: 0px !important;
   height: 100% !important;

 }

 .profile_popup {
   float: right;
   margin-right: 62px;
   margin-top: -20px;
 }

 .profile_popup ul li {}

 .add_money {
   background-color: #FFFCF2;
   padding: 30px;
 }

 .price_btn {

   cursor: pointer;
   background-color: #FCFEFF;
   border-radius: 100px;
   border: 1px solid #DADADA;
   width: 82px;
   height: 29px;
   padding-top: 4px;
   text-align: center;
   margin-right: 10px;
 }

 .procced_btn{
  margin-top: 130px;
  width: 100%;
height: 37px;
background: #FFC900;
border-radius: 29px;
border: none;
color: white;
margin-bottom: 100px;
 }

 .transactions_history{
  padding: 60px;
 }
 .card_payment{
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 30px;
 }