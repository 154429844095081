ol,
ul {
  padding-left: 0;
}

ul,
li,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

table {
  width: 100%;
}

/* main css  */
.admin-toggle {
  display: none;
}

.dashboard-part {
  width: 100%;
  display: flex;
  background-color: #f6f7f8;
}

.dashboard-part>.left-part {
  width: 147px;
  background-color: #FFDC5A;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  transition: all 1s;
  margin-top: -58px;
  transition: all .3s;
  min-height: 380px;
}

.adminlogo {
  padding: 15px 2px 0px 18px;
  cursor: pointer;
}

.adminmenulist {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  justify-content: space-between;
  overflow: auto;
  padding-top: 15px;
}

.adminmenu li a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: block;
  padding: 13px 2px 13px 18px;
  position: relative;
  transition: all 1s;
  background-color: #FFDC5A;
  line-height: 1;

}

.adminmenu li a svg {
  width: 18px;
  display: inline-block;
  vertical-align: bottom;
  transition: all .3s;
  margin-right: 10px;
}

.pagination_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_botton {
  padding: 0.4rem 1.2rem;
  border: none;
  font-size: 1rem;
  background-color: #FFDC5A;
  color: #ffffff;
  margin: 1rem;
  cursor: pointer;
  display: inline-block;
}

.permission_blur {
  background-color: #6e6d6d91 !important;
  opacity: 0.4;
  cursor: not-allowed !important;
}


.paginaption_p {
  margin: 0.5rem 0;
  color: rgba(21, 19, 60, 0.5);
  font-weight: 400;
  font-size: 1.2rem;
  display: inline-block;
}

.adminmenu li a:hover {
  color: #fff;
  transition: all .3s;
}

.adminmenu li a.active,
.adminmenu li a.active:hover {
  background-color: #f6f7f8;
  color: #000;
  transition: all 1s;
}

.adminmenu li a.active:after {
  content: "";
  transition: all .3s;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #ffdc5a;
  top: -20px;
  border-radius: 0px 0 30px 0;
  box-shadow: 5px 5px 0px 2px #f6f7f8;
  right: 0px;
}

.adminmenu li a.active:before {
  content: "";
  transition: all .3s;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #ffdc5a;
  bottom: -20px;
  border-radius: 0px 30px 0px 0px;
  z-index: 1;
  right: 0;
  box-shadow: 2px -7px 0px 2px #f6f7f8;
}

.logout-link {
  position: relative;
  width: 100%;
}

.logout-link>a {
  color: #000;
}

.bg-red {
  background-color: #ffdc5a;
}

.logout-link>a {
  padding: 12px 5px 12px 18px;
  color: #000;
  text-decoration: none;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  transition: all 1s;

}

.logout-link>a>img {
  margin-right: 15px;
}

.logout-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 1px;
  background-color: rgb(0 0 0 / 20%);
  transition: all.3s;
}

.collapsemenu .dashboard-part>.left-part {
  width: 57px;
  transition: all .3s;
}

.full-log {
  transition: all .3s;
}

.dashboard-part>.left-part::-webkit-scrollbar,
.content-sec::-webkit-scrollbar,
.select-items::-webkit-scrollbar,
.filter-body::-webkit-scrollbar,
.data_picker .rdrDateRangePickerWrapper::-webkit-scrollbar,
.ordertab-sec .tab-content .tab-pane::-webkit-scrollbar,
.summery-table::-webkit-scrollbar,
.delivertype-list::-webkit-scrollbar,
.editpopup::-webkit-scrollbar,
.ordertab-sec.customer-sec .tab-content>div::-webkit-scrollbar,
.editb2b-box .popup-body::-webkit-scrollbar,
.employe-table::-webkit-scrollbar,
.usersetting-table::-webkit-scrollbar,
.invoice-table::-webkit-scrollbar,
.cod-table::-webkit-scrollbar,
.transaction-table::-webkit-scrollbar,
.notification-box>ul::-webkit-scrollbar,
.editpopup-body::-webkit-scrollbar,
.callbuyer-box::-webkit-scrollbar,
.adduser-popup .popup-body::-webkit-scrollbar,
.wallet-popup .popup-body::-webkit-scrollbar,
.trh-box>ul::-webkit-scrollbar,
.popup-body::-webkit-scrollbar,
.adminmenulist::-webkit-scrollbar,
.editb2b-box::-webkit-scrollbar,
.data_picker_btn::-webkit-scrollbar,
.addcategorypopup .popup_body::-webkit-scrollbar,
.trh-box::-webkit-scrollbar,
.trackorderstatuslogin>.left-part>.track-list::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
  height: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .5);
}


.dashboard-part>.left-part::-webkit-scrollbar-thumb,
.content-sec::-webkit-scrollbar-thumb,
.select-items::-webkit-scrollbar-thumb,
.filter-body::-webkit-scrollbar-thumb,
.data_picker .rdrDateRangePickerWrapper::-webkit-scrollbar-thumb,
.ordertab-sec .tab-content .tab-pane::-webkit-scrollbar-thumb,
.summery-table::-webkit-scrollbar-thumb,
.delivertype-list::-webkit-scrollbar-thumb,
.editpopup::-webkit-scrollbar-thumb,
.ordertab-sec.customer-sec .tab-content>div::-webkit-scrollbar-thumb,
.editb2b-box .popup-body::-webkit-scrollbar-thumb,
.employe-table::-webkit-scrollbar-thumb,
.usersetting-table::-webkit-scrollbar-thumb,
.invoice-table::-webkit-scrollbar-thumb,
.cod-table::-webkit-scrollbar-thumb,
.transaction-table::-webkit-scrollbar-thumb,
.notification-box>ul::-webkit-scrollbar-thumb,
.editpopup-body::-webkit-scrollbar-thumb,
.callbuyer-box::-webkit-scrollbar-thumb,
.adduser-popup .popup-body::-webkit-scrollbar-thumb,
.wallet-popup .popup-body::-webkit-scrollbar-thumb,
.trh-box>ul::-webkit-scrollbar-thumb,
.popup-body::-webkit-scrollbar-thumb,
.adminmenulist::-webkit-scrollbar-thumb,
.editb2b-box::-webkit-scrollbar-thumb,
.data_picker_btn::-webkit-scrollbar-thumb,
.addcategorypopup .popup_body::-webkit-scrollbar-thumb,
.trh-box::-webkit-scrollbar-thumb,
.trackorderstatuslogin>.left-part>.track-list::-webkit-scrollbar-thumb {
  background-color: #FFC900;
  border-radius: 10px;
}

.userdetailpart .datatable table thead::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
  height: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .5);
}

.userdetailpart .datatable table thead::-webkit-scrollbar-thumb {
  background-color: #FFC900;
  border-radius: 10px;
}

.userdetailpart .datatable table tbody::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
  height: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .5);
}

.userdetailpart .datatable table tbody::-webkit-scrollbar-thumb {
  background-color: #FFC900;
  border-radius: 10px;
}




.collapsemenu .adminlogo>.full-logo,
.short-logo {
  display: none;
  transition: all .3s;
  font-size: 0;
}

.collapsemenu .adminmenu li a,
.collapsemenu .logout-link>a {
  font-size: 0;
  transition: all .3s;
}

.collapsemenu .short-logo {
  transition: all .3s;
}

.collapsemenu .short-logo {
  display: inline-block;
  transition: all .3s;

}

.collapsemenu .adminmenu li a.active:after,
.collapsemenu .adminmenu li a.active:before {
  transition: all .3s;
}

.collapsemenu .dashboard-part>.content-sec,
.collapsemenu .adminheader-bar {
  width: calc(100% - 57px);
  transition: all .3s;
}


.adminheader-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  width: calc(100% - 147px);
  box-shadow: 0px 1px 3px 0px #00000012, 0px 1px 2px -2px #0000000F, 0px 0px 1px 1px #0000000D;
  margin-left: auto;
  transition: all 1s;
  position: relative;
  z-index: 11111111;
  background-color: #fff;
}

.adminheader-bar>.left-part {
  width: 395px;
  /* display: block !important; */
}

.adminheader-bar .form-group {
  width: 100%;
  position: relative;
}

.adminheader-bar .form-group input {
  width: 100%;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: #F6F7F8;
  padding: 7px 38px 7px 12px;
  font-size: 14px;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.textprevent {
  /* -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none; */
}

.adminheader-bar input:placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 12px;
}

.adminheader-bar .form-group .search-icon {
  position: absolute;
  right: 20px;
  top: 4px;
  cursor: pointer;
  z-index: 1;
}

.adminheader-bar>.left-part .form-group>ul {
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
  background: #ffffff;
  padding: 5px 15px !important;
  color: #000;
  border-radius: 5px;
  overflow: auto;
  cursor: pointer;
}

.adminheader-bar>.left-part .form-group>ul>li {
  color: #000;
}

.adminheader-bar>.right-part {
  /* width: 100%; */
}

.adminheader-bar>.right-part>ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 310px; */
  margin-bottom: 0;
}

.adminheader-bar>.right-part>ul>li+li {
  margin-left: 10px;
}

.searchicon {
  display: none;
}

.adminheader-bar>.right-part>ul .user-part {
  width: auto;
  max-width: 200px;
  display: flex;
  position: relative;
}

.adminheader-bar>.right-part>ul .user-part .dropdown-menu {
  right: 0;
}


.uploadLabel>div {
  position: relative;
  height: 30px;
  cursor: pointer;
  padding-left: 17px;

}

.permission_blur>.permis>input {
  cursor: not-allowed !important;
}

.uploadLabel>div:hover {
  background: #e9ecef;
}

.uploadLabel input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  display: block;
  cursor: pointer;
  width: 100%;
}

.uploadLabel>div:after {
  position: absolute;
  content: 'Upload Pincode';
  position: absolute;
  top: 0px;
  left: 17px;
  display: block;
  cursor: pointer;
  z-index: 20;
  height: 100%;
  width: calc(100% - 17px);
}

.adminheader-bar>.right-part>ul .user-part img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.adminheader-bar>.right-part>ul .user-part h6 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.adminheader-bar>.right-part>ul .user-part p {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400;
}

/* ============================== Notification popup ================================ */

.notification-box {
  position: fixed;
  top: 55px;
  width: 79%;
  max-width: 400px;
  right: 0;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  z-index: 99999;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 15px 0 15px 15px;
  height: calc(100vh - 55px);


}

.notification-box .title {
  display: flex;
  justify-content: space-between;
}

.notification-box .title h2 {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  padding-right: 10px;
  margin-bottom: 0;
  line-height: 1;
}

.notification-box .title .btn {
  color: #FFC900;
  font-size: 14px;
  font-weight: 600;
  padding-top: 0;
}

.notification-box .title .btn svg {
  width: 30px;
}

.notification-box>ul {
  margin-top: 25px;
  padding-bottom: 15px;
  overflow-y: auto;
  padding-right: 2px;
  height: calc(100% - 46px);
}

.notification-box>ul>li {
  border-bottom: 1px solid #ddd;
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px
}

.notification-box>ul>li:after {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #FFC900;
}

.notification-box>ul>li p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.notification-box>ul>li>div {
  color: #838383;
  font-size: 14px;
}

.notification-box>ul>li+li {
  margin-top: 15px
}


.meter-box,
.rev-box {
  display: flex;
  justify-content: space-between;
}

.meter-box .admin-box:first-child,
.rev-box .admin-box:first-child {
  width: 68%;
}

.meter-box .admin-box:last-child,
.rev-box .admin-box:last-child {
  width: 30%;
}

/* ==================================================================== */

.content-sec {
  width: calc(100% - 147px);
  padding: 15px;
  overflow-y: auto;
  height: calc(100vh - 58px);
  padding-bottom: 10px;
  transition: all .3s;
}

.dashboardcontent-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px;
}

.dashboardcontent-title h2 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

.dashboardcontent-title .filter-part,
.filter-part-codRemittance {
  position: relative;
  z-index: 999;
}

.dashboardcontent-title .search-box {
  position: relative;
}

.dashboardcontent-title .search-box .form-control {
  border-radius: 30px;
  background-color: #F7F8FC;
  border: 1px solid #F7F8FC;
  box-shadow: none;
  padding-right: 28px;
}

.dashboardcontent-title .search-box>span {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 1;
}

.adminorder-list {
  display: flex;
  margin-bottom: 0;
}

.adminorder-list li {
  width: calc(100%/3);
  display: flex;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
}

.adminorder-list .order-text {
  text-align: right;
}

.recharts-line-dots>circle {
  display: none;
}

.adminorder-list li+li {
  margin-left: 45px;
}

.adminorder-list li figure {
  width: 56px;
  height: 56px;
  background: #FDF6DC;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-bottom: 0;
}

.adminorder-list li>div {
  width: calc(100% - 71px);
}

.adminorder-list li h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.adminorder-text {
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.adminorder-list li h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.admin-box {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding-bottom: 15px;
  background-color: #fff;
  margin-top: 25px;
}

.title-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, .25);
  padding: 15px 0;
  margin: 0 20px;
}

.title-part h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.adminactive-part {
  overflow: hidden;
  position: relative;
}

.admin-dropdown {
  position: relative;
  z-index: 99;
}

.admin-dropdown .form-select {
  font-size: 10px;
  padding: 5px 12px 5px 2px;
  background-position: right 2px center;
  background-size: 10px;
}


.admin-dropdown>div {
  box-shadow: none;
  padding: 2px 30px 2px 10px !important;
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  position: relative;
}

.admin-dropdown>div:after {
  position: absolute;
  content: "";
  top: 4px;
  right: 8px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-width: 0px 0px 10px 10px;
  border-color: transparent transparent #000 transparent;
  transform: rotate(45deg);
}

.admin-dropdown>ul {
  cursor: pointer;
  position: absolute;
  top: 25px;
  left: 0;
  width: 90px;
  padding: 15px;
  padding: 0px 0px 5px 5px !important;
  background-color: #ffffffe3;
  border-radius: 4px;

}

.admin-dropdown>ul li {
  padding-top: 8px;
  font-size: 12px;
}

.transaction-box .title-part {
  margin-bottom: 0;
}

.transaction-table {
  width: 100%;
  padding: 15px 20px 0;
  height: 280px;
  overflow: auto;
}

.transaction-table table {
  width: 100%;
}

.transaction-box table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.transaction-box table th {
  font-size: 15px;
  font-weight: 650;
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 10px;
}

.transaction-box table td {
  color: #696969;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 0;
}

.transaction-box table td b {
  font-weight: 500;
}


.transaction-box table th:first-child,
.transaction-box table td:first-child {
  width: 15%;
}


.transaction-box table td:nth-child(2),
.transaction-box table th:nth-child(2) {
  width: 25%;
  text-align: center;
}

.transaction-box table td:nth-child(3),
.transaction-box table th:nth-child(3) {
  width: 20%;
  text-align: center;
}

.transaction-box table td:nth-child(4),
.transaction-box table th:nth-child(4) {
  width: 25%;
  text-align: center;
}

.transaction-box table td:last-child,
.transaction-box table th:last-child {
  text-align: center;
}

.transaction-box table tr:last-child td {
  border-bottom: none;
}

.dashboardcontent-part>.right-part {
  width: 28%;
}

.circle-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.circle-checkbox input {
  visibility: hidden;
  cursor: pointer;
  width: 0;
}

.checkbox-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.pending-box .checkbox-mark {
  background: #FFC900;
}

.issue-box .checkbox-mark {
  background: #FF0020;
}

.rto-box .checkbox-mark {
  background: rgb(0, 143, 251)
}

.rto-delivered-box .checkbox-mark {
  background: rgb(119, 93, 208)
}


.delivery-box .checkbox-mark {
  background: #08D110;
}

.late-box .checkbox-mark {
  background: #FF8594;
}

.checkbox-mark:after {
  content: "";
  position: absolute;
  display: none;
}

.circle-checkbox input:checked~.checkbox-mark:after {
  display: block;
}

.permission_blur {
  background-color: #6e6d6d91 !important;
  opacity: 0.3;
  cursor: not-allowed !important;
  z-index: 99;
}

.permission_blur_resolve {
  background-color: #6e6d6d91 !important;
  opacity: 0.3;
  cursor: not-allowed !important;
  z-index: 99;
  padding: 5px 20px;
  /* width: 100px; */
}

.permission_blur>button {
  cursor: not-allowed !important;
}

.permission_blur>input {
  cursor: not-allowed !important;
}

/* 
.circle-checkbox .checkbox-mark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
} */

.apexcharts-align-center,
.rdrDefinedRangesWrapper {
  display: none !important;
}

.data_picker {
  width: 90%;
  max-width: 685px;
  position: relative;
}

.data_picker_btn button {
  float: right;
  border-radius: 5px;
  width: 70px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  border: none;
  color: white;
  background-color: #E7BC1C;
  padding-bottom: 7px;
  padding-top: 6px;
  font-size: 18px;
}

.data_picker .rdrDateRangePickerWrapper {
  overflow: auto;
  width: 100%;
}

.data_picker .rdrCalendarWrapper {
  padding: 20px 10px 10px;
  min-width: 650px;
}

.calender_popup_cancel {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------ order part  ---------------------------  */

.Toastify__toast-container--top-right {
  z-index: 99999999999;
}

.display_opacity {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.input_filed_zIndex {
  z-index: -1 !important;
  color: #928e8e;

}



.rdrEndEdge,
.rdrInRange,
.rdrStartEdge {
  background-color: #E7BC1C !important;
  /* border: none !important; */
}

.rdrDayStartPreview .rdrDayEndPreview,
.rdrDayHovered {
  border: none !important
}


.order_status div button {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
  z-index: -1 !important;
}

.ordertittle-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0 40px;
}

.dashboardcontent-title .ordertittle-part {
  margin-right: 40px;
}

.companyDropDown {
  overflow: auto;
  /* border: 1px solid #d8d8d8; */
  padding: 0px;
  cursor: pointer;
  min-height: 30px;
  max-height: 100px;
  background-color: #f1f1f1;
  border-radius: 10px;
  /* padding-left: 10px; */
}

.companyDropDown>div {
  padding: 0 5px;
}


.ordertittle-part h2 {
  font-size: 32px;
  color: #000;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  margin-right: 35px;
}

.ordertittle-part .form-select {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  background-color: rgba(255, 201, 0, 0.1);
  border-radius: 4px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
}




.ordertittle-part>ul {
  display: flex;
  margin-bottom: 0;
}

.ordertittle-part>ul li.form-control,
.invoice-sec>.title .form-control {
  position: relative;
  margin-left: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 5%), 0px 1px 2px -2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 7%);
  padding: 14px 14px;
  margin-left: 17px;
  width: auto;
  z-index: 1;
}


.ordertittle-part>ul li.form-control input,
.invoice-sec>.title .form-control input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.upload-form-control {
  position: relative;
  margin-left: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 5%), 0px 1px 2px -2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 7%);
  padding: 6px 14px;
  margin-left: 17px;
  width: auto;
  z-index: 1;
  height: 42%;
}

.upload-form-control input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.upload-form-control:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  background: url(../Images/upload.svg) center center no-repeat;
  background-size: 90%;
  display: block;
  pointer-events: none;
  z-index: 20;
  height: 22px;
  width: 22px;
}



.ordertittle-part>ul li.form-control:after,
.invoice-sec>.title .form-control:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  background: url(../Images/upload.svg) center center no-repeat;
  background-size: 90%;
  display: block;
  pointer-events: none;
  z-index: 20;
  height: 22px;
  width: 22px;
}




.ordertittle-part>ul .form-group {
  width: 287px;
  position: relative;

}

.ordertittle-part>ul .form-group input {
  border-radius: 4px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #fff;
  padding: 4px 30px 4px 12px;
  font-size: 14px;
  color: #000;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
}

.ordertittle-part>ul .form-group .search-icon {
  position: absolute;
  right: 10px;
  top: -1px;
  z-index: 1;
  cursor: pointer;
}

.add-item,
.download-item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  width: 30px;
  height: 30px;
  margin-left: 17px;
}

.ordertab-sec {
  position: relative;
}

.filter-part {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 3;
}

.filter-part-codRemittance {
  float: right;
}

.filter-part-codRemittance>.btn,
.filter-part>.btn {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  width: 91px;
  border: none;
  padding: 5px 10px 5px 10px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
}

.pagination_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_botton {
  padding: 0.4rem 1.2rem;
  border: none;
  font-size: 1rem;
  background-color: #FFDC5A;
  color: #ffffff;
  margin: 1rem;
  cursor: pointer;
  display: inline-block;
}

.permission_blur {
  background-color: #6e6d6d91 !important;
  opacity: 0.4;
  cursor: not-allowed !important;
}


.paginaption_p {
  margin: 0.5rem 0;
  color: rgba(21, 19, 60, 0.5);
  font-weight: 400;
  font-size: 1.2rem;
  display: inline-block;
}


.filter-body {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 310px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  padding: 15px;
  border-radius: 4px 0 0 4px;
  height: calc(100vh - 55px);
  margin-top: 55px;
  overflow: auto;
  z-index: 1;
}

.filter-body .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.filter-body h4 {
  font-size: 24px;
  color: #000;
  line-height: 32px;
  font-weight: 700;
}

.filter-body h5 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-top: 25px;
}

.filter-body h5 span {
  color: #B7B7B7;
  font-weight: 400;
  font-size: 10px;
}

.filter-body .form-group {
  width: 100%;
  position: relative;
}

.filter-body .form-group input {
  width: 100%;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: #F6F7F8;
  padding: 7px 38px 7px 12px;
  font-size: 14px;
  color: #000;
}

.filter-body .form-group .search-icon {
  position: absolute;
  right: 20px;
  top: 4px;
  cursor: pointer;
  z-index: 1;
}

.filter-body .checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.filter-body .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.filter-body .checkbox .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid #FFC900;
  border-radius: 4px;
}

.filter-body .checkbox input:checked~.checkmark {
  background-color: #FFC900;
}

.filter-body .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-body .checkbox input:checked~.checkmark:after {
  display: block;
}

.filter-body .checkbox .checkmark:after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.statusbtn-group .btn {
  background: #fce073;
  border-radius: 16px;
  font-size: 10px;
  color: #000;
  font-weight: 600;
  border: none;
  padding: 4px 10px;

}

.statusbtn-group .btn.btn-active {
  background: linear-gradient(0deg, #E7BC1C 5.99%, #FFECA5 105.26%);
  color: #fff;
}

.statusbtn-group .btn:nth-child(2) {
  margin-left: 10px;
}

.statusbtn-group .btn:last-child {
  margin-top: 10px;
}

.btn-cancel {
  background: #EAEAEA;
  color: #B7B7B7;
  width: 119px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
}

.btn-cancel:hover {
  background-color: #7d7d7d;
  color: #ffffff;
}

.btn-apply {
  background: #FFDC5A;
  border: 1px solid #FFDC5A;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border-radius: 6px !important;

}

.btn-apply:hover {
  border: 1px solid #FFC900;
  color: #fff;
  background-color: #FFC900;

}


.express-box select {
  margin-top: 20px;
  font-size: 14px;
  border: none;
  background-color: #F7F8FC;
  color: #928e8e;
}




.select-selected {
  background: #F7F8FC;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 7px;
  right: 10px;
  width: 7px;
  height: 7px;
  border: 1px solid transparent;
  border-color: #000 #000 transparent transparent;
  transform: rotate(135deg);
}

.select-selected.select-arrow-active:after {
  border-color: #000 #000 transparent transparent;
}

.select-items div,
.select-selected {
  color: #000;
  padding: 2px 10px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  font-size: 12px;
}

.select-items {
  position: absolute;
  background: #F7F8FC;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  height: 150px;
  overflow-y: auto;
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.filterbtn-group {
  position: absolute;
  bottom: 0;
  z-index: 1;
  margin: 0 0 25px;
  width: 89%;
}

.filterbtn-group button {
  width: 100%;
  display: block;
}


.ordertab-sec .nav-tabs {
  position: relative;
  padding-right: 95px;
}

.ordertab-sec .nav-tabs .nav-link {
  margin: 0;
  border: none;
  color: #000;
  position: relative;
  z-index: 1;
  line-height: 19px;
  padding: 14px 5px;
  min-width: 107px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
}

.ordertab-sec .nav-tabs .nav-link.active {
  color: #fff;
  background: #FFDC5A;
}

.orderTab li {
  padding-right: 20px;

}

.ordertab-sec .tab-content {
  background-color: #fff;
  margin-top: 35px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 10px;
}

.transition-popup .tab-content {
  overflow: auto;
}

.transition-popup .tab-content table {
  width: 100%;
  min-width: 500px;
}


/* .TransactionTable */
.tab-content>div>table th {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  line-height: 22px;
  padding: 0 5px 12px;
  width: 222px;
  /* width: calc(100%/6 - 1px); */
}

.tab-content table tr th:first-child,
.tab-content table td:first-child {
  /* width: 15%; */
  /* width: 222px; */
}

.tab-content table th:nth-child(2),
.tab-content table td:nth-child(2) {
  /* width: 25%; */
  text-align: center;
  /* width: 222px; */

}

.tab-content table th:nth-child(3),
.tab-content table td:nth-child(3) {
  /* width: 16%; */
  /* width: 222px; */
  /* text-align: center; */
  text-align: center;
}

.tab-content table th:nth-child(4),
.tab-content table td:nth-child(4) {
  /* width: 15%; */
  /* width: 222px; */
  text-align: center;
}

.tab-content table th:nth-child(5),
.tab-content table td:nth-child(5) {
  /* width: 15%; */
  /* width: 222px; */
  text-align: center;
}

.tab-content #returns-tab-pane table th:nth-child(6),
.tab-content #returns-tab-pane table td:nth-child(6) {
  text-align: center;
}

.tab-content table th:last-child,
.tab-content table td:last-child {
  width: 120px;
  min-width: 120px;
  text-align: center;
}

.tab-content table td {
  font-size: 14px;
  color: #757575;
  font-weight: 400;
  line-height: 20px;
  padding: 20px 5px;
  border-top: 1px solid #D3D3D3;
  line-height: normal;
  width: 222px;
}

.tab-content table td .btn-green,
.tab-content table td .btn-old {
  padding: 2px 2px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  min-width: 50px;
}

.tab-content table td .btn-green {
  color: #08D110;
  background: #08D11033;
}

.tab-content table td .btn-old {
  color: #FF7D00;
  background-color: rgba(255, 125, 0, 0.2);
}

.tab-content table td .btn-ship {
  padding: 6px 2px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  width: 105px;
  background-color: #FFC900;
  color: #fff;
}


.action-btngroup {
  /* display: flex; */
  justify-content: space-between;
}

.order-btn {
  padding: 0;
  border-radius: 0;
  border: none;
  outline: none;
  position: relative;
}

.order-btn>.dropdown {
  position: absolute;
  top: 40px;
  right: -15px;
  /* width: 100px; */
  padding: 3px 2px 5px 5px !important;
  background: #FFFFFF;
  border: 0.5px solid #EAEAEA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  text-align: left;
  z-index: 1;
}

.order-btn>.dropdown {
  top: 30px !important;
}

.permission_all {
  top: 20px !important;
  height: 80px;
  overflow: overlay;
}

.address_all {
  padding-top: 10px;
  top: 30px !important;
  height: 35px;
  overflow: overlay;
}

.permission_all li {
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 3px;
  padding-top: 4px;
  font-size: 14px;
}



.order-btn .dropdown li a {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.order-btn .dropdown:after {
  position: absolute;
  content: "";
  top: -6px;
  right: 15px;
  width: 10px;
  height: 10px;
  border: solid rgba(0, 0, 0, 0.25);
  border-width: 1px 1px 0 0;
  background-color: #fff;
  transform: rotate(-45deg);
}

.rdrDateRangeWrapper {
  padding: 30px;
}

.calender_popup_cancel {
  cursor: pointer;
}

.tab-content .pending-tabpane table th:first-child,
.tab-content .pending-tabpane table td:first-child {
  width: 172px;
}

.tab-content .pending-tabpane table th:nth-child(2),
.tab-content .pending-tabpane table td:nth-child(2) {
  /* width: auto; */
  width: 10%;
  text-align: center;
}

.tab-content .pending-tabpane table th:nth-child(3),
.tab-content .pending-tabpane table td:nth-child(3) {
  /* width: auto; */
  width: 222px;
  text-align: center;

}

.tab-content .pending-tabpane table th:nth-child(4),
.tab-content .pending-tabpane table td:nth-child(4) {
  /* width: auto; */
  width: 222px;
  text-align: center;
}

.tab-content .pending-tabpane table th:nth-child(5),
.tab-content .pending-tabpane table td:nth-child(5),
.tab-content .pending-tabpane table th:nth-child(6),
.tab-content .pending-tabpane table td:nth-child(6) {
  width: 222px;
  text-align: center;
}

.tab-content .pending-tabpane table th:nth-child(7),
.tab-content .pending-tabpane table td:nth-child(7) {
  width: 100px;
  text-align: center;
}

.tab-content .pending-tabpane table th:last-child,
.tab-content .pending-tabpane table td:last-child {
  width: 180px;
  text-align: center;
}

.tab-content .pending-tabpane table td .form-select {
  border-color: #FFC900;
  color: #FFC900;
  position: relative;
  background-image: none;
  padding-right: 0;
  display: block;
}

.tab-content .pending-tabpane table td .form-select:before {
  /* content: "dsadsewe";
  position: absolute;
  color: #08D110  ;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background: red;
  border-left: 5px solid #ffc900;
  border-top: 5px solid #ffc900; */

  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: red transparent transparent transparent;

}

.tab-content .pending-tabpane table td select option {
  color: #000;

}



.pending-tabpane .action-btngroup button {
  border: none;
  border-radius: 50%;
  outline: none;

}

.action-btngroup button {
  border: none;
  border-radius: 50%;
  outline: none;
  margin-right: 13px;
}

.actionordergroup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.actionorder-menulist {
  position: absolute;
  width: 110px;
  top: 32px;
  left: -80px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 9;
  padding: 3px;
}

.actionorder-menulist li a {
  color: #000;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 11px;
}

/* .actionorder-menulist:after {
  position: absolute;
  content: "";

 } */

.actionorder-menulist li+li {
  margin-top: 5px;
}


/* Booked tab part */

.tab-content #booked-tab-pane table tr th:first-child,
.tab-content #booked-tab-pane table td:first-child,
.tab-content #transit-tab-pane table tr th:first-child,
.tab-content #transit-tab-pane table td:first-child,
.tab-content #outfordelivery-tab-pane table tr th:first-child,
.tab-content #outfordelivery-tab-pane table td:first-child,
.tab-content #delivered-tab-pane table tr th:first-child,
.tab-content #delivered-tab-pane table td:first-child,
.tab-content #returns-tab-pane table tr th:first-child,
.tab-content #returns-tab-pane table td:first-child,
.tab-content #rto-delivered-tab-pane table tr th:first-child,
.tab-content #rto-delivered-tab-pane table td:first-child,
.tab-content #cancel-tab-pane table tr th:first-child,
.tab-content #cancel-tab-pane table td:first-child {
  width: 9%;
  /* text-align: center; */
}

.tab-content #booked-tab-pane table th:nth-child(2),
.tab-content #booked-tab-pane table td:nth-child(2) {
  /* width: 15%; */
  text-align: center;
}

.tab-content #booked-tab-pane table th:nth-child(3),
.tab-content #booked-tab-pane table td:nth-child(3) {
  /* width: 13%; */
}

.tab-content #booked-tab-pane table th:nth-child(4),
.tab-content #booked-tab-pane table td:nth-child(4) {
  width: 12%;
  text-align: center;
}

.tab-content #booked-tab-pane table th:nth-child(5),
.tab-content #booked-tab-pane table td:nth-child(5) {
  width: 16%;
  text-align: center;
}

.tab-content #booked-tab-pane table th:nth-child(6),
.tab-content #booked-tab-pane table td:nth-child(6) {
  width: 21%;
  text-align: center;
}

.tab-content #booked-tab-pane table th:last-child,
.tab-content #booked-tab-pane table td:last-child {
  width: 5%;
  min-width: 120px;
}





.tab-content #returns-tab-pane table th,
.tab-content #returns-tab-pane table td {
  width: 222px;
}

.tab-content #rto-delivered-tab-pane table th,
.tab-content #rto-delivered-tab-pane table td {
  width: 222px;
}

.multiselectDeliveryBoy>div {
  height: 60px;
  text-align: left;
  width: 100%;
  overflow: unset !important;
}

.multiselectDeliveryBoy .searchWrapper>.chip {
  background-color: #FFDC5A;
  color: #000;
  /* text-align: center; */
  width: 100%;
  /* margin: auto; */
  justify-content: center;
}

.multiselectDeliveryBoy #search_input {
  text-align: center;
}

.multiselectDeliveryBoy .optionContainer {
  background-color: white !important;
}

.multiselectDeliveryBoy .multiSelectContainer input {
  margin-top: -3px !important;
}

.NoneValue .searchWrapper .chip {
  display: none !important;
}

.displayValue .searchWrapper .chip {
  display: block !important;
}

/* ------------------ transit-tab-pane ----------------------- */

.tab-content #transit-tab-pane table tr th:nth-child(6),
.tab-content #transit-tab-pane table td:nth-child(6),
.tab-content #outfordelivery-tab-pane table tr th:nth-child(6),
.tab-content #outfordelivery-tab-pane table td:nth-child(6),
.tab-content #returns-tab-pane table tr th:nth-child(7),
.tab-content #returns-tab-pane table tr td:nth-child(7),
.tab-content #rto-delivered-tab-pane table th:nth-child(6),
.tab-content #rto-delivered-tab-pane table td:nth-child(6),
.tab-content #cancel-tab-pane table th:nth-child(6),
.tab-content #cancel-tab-pane table td:nth-child(6) {
  text-align: center;
}


.tab-content #returns-tab-pane table th:last-child,
.tab-content #returns-tab-pane table td:last-child,
.tab-content #rto-delivered-tab-pane table th:last-child,
.tab-content #rto-delivered-tab-pane table td:last-child {
  width: 150px;
  text-align: center;
}

.tab-content #returns-tab-pane table td:last-child .btn {
  margin-right: 0;
}



/*------------------------------ Orderinner -------------------------------------*/


.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.orderinfo-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 25px;
}

/* .cursor-pointer{
  cursor: pointer;
hover:bg-black
} */

.opnePathData>div .css-1nmdiq5-menu {
  display: none !important;
}

.opnePathDataBlock>div .css-1nmdiq5-menu {
  display: block !important;
}

.css-13cymwt-control {
  border: none !important;
  border-radius: 14px !important;
  background-color: #f6f7f8 !important;
  outline: none !important;
}

.css-1hb7zxy-IndicatorsContainer,
.css-1wy0on6 {
  display: none !important;
}

.orderinfo-header h2 {
  font-size: 32px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
}

.orderinfo-header h2 span {
  color: #FFC900;
  font-weight: 600;
}

.back-btn {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 7px 22px;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  margin-right: 22px;
}

.back-btn:hover {
  background: #ACACAC;
  border: 1px solid #ACACAC;
}

.orderaction {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}

.action-btn,
.delivery-box .btn {
  background: #FFFFFF;
  border: 1px solid #FFC900;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 7px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  outline: none;
  background: #FFDC5A;
  cursor: pointer;
}

.action-btn {
  background: #FFDC5A;
  color: #fff;
  border: 1px solid #FFDC5A;
  border-radius: 6px !important;
}

.action-btn:hover,
.add-remark-btn:hover,
.delivery-box .btn:hover {
  border: 1px solid #FFC900;
  background-color: #FFC900;
}

.delivery-box .btn:hover {
  color: #000
}

.orderaction>.dropdown {
  position: absolute;
  top: 40px;
  right: 30px;
  width: 150px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  padding: 10px !important;
  border-radius: 10px;
  transition: all .3s;
  display: none;

}

.orderaction:hover>.dropdown {
  display: block;
  transition: all .3s;

}

.orderaction>.dropdown li {
  color: #000;
  font-size: 13px;
  transition: all .3s;
  margin-bottom: 5px;
  cursor: pointer;
}

.orderaction>.dropdown li:hover {
  color: #F8485E;
  transition: all .3s;
}

/* =============================================================================== */

.popupouter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11111111;
  transition: all .3s;
  overflow: auto;
  /* display: none;  */
}

.popup-box {
  position: absolute;
  top: 55px;
  right: 0;
  width: 86%;
  max-width: 500px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 0 4px rgb(0, 0, 0, .5);
  z-index: 999;
  background-color: #fff;
  height: calc(100vh - 55px);
  padding-bottom: 0px;
}

.profileview_popupChat .popup-body {
  height: 100%;
}


.profileview_popupChat .popupinner {
  max-width: 1000px;
}

.profileview_Image .popupinner {
  max-width: 800px;
}

.profileview_popup .popupinner {
  max-width: 716px;
  max-height: 500px;
}

.profileview_popup .popupinner .popup-body {
  height: calc(100% - 40px);
}

.profileview_popup .upload-form-control {
  height: 30px;
}



.popup-box .close-btn {
  position: absolute;
  top: 15px;
  right: 15px;

}

.popup-box h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding: 20px 40px 20px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #bfbfbf;
}

.popup-box h2>span {
  color: #9D9D9D;
}

.editpopup-outer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 9999;
}

.editpopup-body {
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 48px);
}

.editpopup h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}

.editpopup-body label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.pacform-box {
  position: relative;
}

.pacform-box>span {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  color: #d2d2d2;
  /* background-color: red; */
  background: rgba(248, 229, 159, 0.2);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
}

.pacform-box>input {
  padding-right: 35px;
}

.editpopup .add-border {
  border-color: rgba(111, 111, 111);
  margin-left: -20px;
  width: calc(100% + 50px);
  margin-top: 30px;
  margin-bottom: 20px;
}

.prepad-box {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.prepad-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.prepad-box .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}


.prepad-box input:checked~.checkmark {
  border-color: #FFC900;
  padding: 3px;
}

.prepad-box:after {
  content: "";
  position: absolute;
  display: none;
}

.prepad-box input:checked~.checkmark::after {
  display: block;
  content: '';
  position: absolute;
  display: block;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FFC900;
}


.selectform-box {
  position: relative;
}

.selectform-box:after {
  position: absolute;
  content: "";
  top: 12px;
  right: 10px;
  width: 7px;
  height: 7px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #bbb;
  transform: rotate(135deg);
}

.selectform-box>select {
  padding-right: 65px;
}

.selectform-box>span {
  position: absolute;
  top: 10px;
  right: 23px;
  font-size: 10px;
  color: #bbb;
}

.selectform-box>span>svg {
  fill: #bbb;
}

.selectform-box>span.active {
  color: #08D110;
}

.selectform-box>span.active>svg {
  fill: #08D110;
}

.editpopup .btn {
  width: 100%;
}


.paginationbutton {
  border: 1px solid lightgray;
  border-radius: 0px;
  padding: 10px;
  cursor: pointer;
}

/* =========================== editb2b-box popup==================================================== */

.editb2b-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  max-width: 1380px;
  overflow: initial;
  display: block;
  background-color: #FFF;
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
}

.editb2b-box .popup-body {
  padding: 0 12px;
}

.editb2b-box h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}

.editb2b-box .close-btn,
.addordertag-popup .close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editb2b-box .close-btn svg,
.addordertag-popup .close-btn svg {
  color: #fef;
  font-size: 25px;
  width: 18px;
}

css-1wy0on6 css-1hb7zxy-IndicatorsContainer .editb2b-box label {
  font-size: 14px;
  color: #000;
}

.editb2b-box .form-control,
.editb2b-box .form-select {
  width: 100%;
  border-radius: 30px;
  font-size: 14px;
  color: #000;
  box-shadow: none;
  margin-top: 5px;
}

.editb2b-box .btngroups {
  text-align: right;
  margin-top: 50px;
}

.btngroups button,
.remarkgroups button {
  border-radius: 30px;
  padding: 4px 28px;
  font-size: 14px;
  border-radius: 0;
}

.btngroups button.save-btn,
.remarkgroups button.save-btn {
  background: #FFDC5A;
  color: #fff;
  border: 1px solid #FFDC5A;
  border-radius: 6px;

}

.btngroups button.cancel-btn,
.remarkgroups button.cancel-btn {
  border: 1px solid #B6B6B6;
  color: #5F5F5F;
  margin-left: 15px;
  border-radius: 6px;
}

.transition-popup .editb2b-box {
  /* height: 450px; */
  position: relative;
  transform: none;
  top: 0;
  left: 0;
  max-height: 510px;
  height: 90%;
  margin: 35px auto;
  overflow: initial;


}


.transition-popup .editb2b-box h2 {
  margin-bottom: 35px;
}



/* =============================================================================== */

.ordernumber-part {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ordernumber-part>.left-part {
  width: 65%;
}

.ordernumber-part>.right-part {
  width: 33%;
}

.box {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
}

.summery-table table {
  width: 100%;
}

.summery-table table th {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 0 0 12px;
  border-bottom: 1px solid #000;
}

.summery-table table th>b {
  font-size: 18px;
  font-weight: 700;
}

.summery-table table td {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 12px 0;
  border-top: 1px solid #BDBDBD;
}

.summery-table table td>img {
  margin-right: 10px;

}

.summery-table table th:first-child,
.summery-table table th:first-child {
  width: 90px;
}

.summery-table table th:nth-child(2),
.summery-table table td:nth-child(2) {
  width: 80px;
  text-align: center;
}

.summery-table table th:nth-child(3),
.summery-table table td:nth-child(3) {
  width: 60px;
  text-align: center;
}

.summery-table table th:nth-child(4),
.summery-table table td:nth-child(4) {
  text-align: center;
  width: 70px;
}

.summery-table table th:last-child,
.summery-table table td:last-child {
  width: 40px;
  text-align: center;
}

.customer-part {
  margin-top: 20px;
}

.customer-part h2,
.delivery-box h2,
.order-box li h2 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  line-height: normal;
  margin-bottom: 15px;
}

.customer-part ul li {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #BDBDBD;
  padding: 15px 0;
}

.customer-part ul li h4,
.order-box li h5,
.delivery-box h5 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.customer-part ul li p,
.order-box li p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  width: 200px;
  margin-bottom: 0;
  padding-left: 5px;
}

.customer-part ul li h4 {
  width: 150px;
  /* word-wrap: break-word; */
}

.customer-part ul li P {
  width: 240px;
  /* word-wrap: break-word; */

  word-break: break-all;

}

.customer-details-part {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 25px;
}

.add-remark-btn {
  background: #FFFFFF;
  border: 1px solid #FCC900;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 5%), 0px 1px 2px -2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 7%);
  border-radius: 4px;
  padding: 7px 22px;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  margin-right: 22px;
  border-radius: 4px;
}

.order-box li p {
  word-break: break-word;
}

/* .delivery-box .btn {
  color: #FFC900;
}

.delivery-box .btn:hover {
  color: #fff;
} */

.order-box li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.order-box li h2 {
  margin-bottom: 0;
}

.order-box button {
  background: #FFF2C0;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  color: #000;
  font-size: 10px;
  border: none;
  padding: 2px 5px;
}

.order-box li p {
  text-align: right;
}


/*------------------------------ User Page -------------------------------------*/
.orderdetail-part {
  max-width: 700px;
  margin: 0 auto;
}

.bulkorder {
  background-color: #f1c40f;
  float: right;
  margin-top: 35px;
}

.bulkorder .bulkbtn {
  width: 150px;
}

.user-list {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 50px 0 0;
}

.user-list:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 87%;
  height: 1px;
  background-color: #C5C5C5;
  transition: all .3s;
}

.user-list li {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  padding: 30px 0 0;
  width: 110px;
  text-align: center;
}

.user-list li>span {
  position: absolute;
  top: -18px;
  left: 50%;
  z-index: 1;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #DADADA;
  transform: translate(-50%, 0);
}

.user-list li>span:after {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 7px;
  left: 7px;
  width: 18px;
  height: 18px;
  background-color: #eee;
  z-index: 3;
}

.user-list li.active>span {
  border: 2px solid #FFC900;
}

.user-list li.active>span:after {
  background-color: #FFC900;
}

.user-list li.active>span:before {
  content: "";
  position: absolute;
  top: 15px;
  right: 32px;
  width: 181px;
  height: 1px;
  background-color: #FFC900;
  z-index: -1;
  transition: all .3s;
}

.user-list li.active:first-child span:before {
  width: 0;
  transition: all .3s;
}



.user-list li:last-child {
  width: 60px;
}

.user-list li.active:last-child>span:before {
  width: 155px;
}

.userinfo-box {
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  margin-top: 25px;
  background-color: #fff;
  overflow-x: hidden;
}

.userinfo-box h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  padding: 15px 30px;
  border-bottom: 1px solid #d8d8d8;
}

.userinfo-body {
  padding: 15px 30px 45px;
  border-bottom: 1px solid #d8d8d8;
}

.userinfo-body h3 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.userinfo-body label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}

.userinfo-body input,
.userinfo-body .form-control,
.userinfo-body .react-tel-input {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  border-radius: 50px;
  box-shadow: none;
  border: 1px solid #D9D9D9;
}

.userinfo-body .react-tel-input .form-control {
  border-radius: 50px;
  border: 1px solid #0000;

}

.userinfo-body .react-tel-input .flag-dropdown,
.userinfo-body .react-tel-input .selected-flag,
.userinfo-body .react-tel-input .flag-dropdown.open,
.userinfo-body .react-tel-input .selected-flag {
  border-radius: 50px 0 0 50px;
  border: 1px solid #0000;
  /* background: red; */
}

.userinfo-body input:focus {
  box-shadow: none;
  border-color: #d8d8d8;
}

.userinfo-body input::placeholder {
  color: #d8d8d8;
  font-weight: 400;
  font-size: 10px;
}

.gen-btn {
  color: #fff;
  background: #FFC900;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  padding: 5px 18px;
  border: 1px solid #FFC900;
  margin-top: 30px;
}

.gen-btn:hover {
  color: #FFC900;
  background-color: #fff;
}

.next-btn {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #000;
  background-color: #000;
  width: 100%;
  max-width: 90%;
  display: block;
  border-radius: 50px;
  margin: 25px auto;
}

.next-btn:hover {
  color: #000;
  background-color: #fff;
  border-color: #000;

}

.b2b-box label {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.b2b-box label>span {
  font-weight: 400;
  color: #d8d8d8;
  font-size: 14px;
  display: block;
}

.b2b-box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 5px;
}

.b2b-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.b2b-box .checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #FFC900;
  margin: 0;
  border-radius: 3px;

}


.b2b-box:hover input~.checkmark {
  background-color: #fff;
}


.b2b-box input:checked~.checkmark {
  background-color: #FFC900;
}

.b2b-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.b2b-box input:checked~.checkmark:after {
  display: block;
}

.b2b-box .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.b2b-box>div {
  color: #ddd;
  font-weight: 400;

}

.userinfo-body .form-box {
  position: relative;
}

.userinfo-body .form-box input {
  padding-right: 45px;
}

.userinfo-body .form-box span {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  color: #fff;
  background-color: #FFC900;
  border-radius: 0 50px 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.addressdetail-list {
  position: relative;
}

.addressdetail-list:after {
  position: absolute;
  content: "";
  top: 23px;
  left: 9px;
  width: 1px;
  height: 54%;
  border-left: 1px dashed #FFC900;
}

.addressdetail-list>li {
  display: flex;
}

.addressdetail-list>li+li {
  margin-top: 60px;
}

.addressdetail-list li>span {
  width: 20px;
  margin-right: 10px;
}

.addressdetail-list li>.right-part>p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  word-break: break-all;
}

.addressdetail-list li>.right-part>p>span {
  color: #d8d8d8;
  font-size: 14px;
}

.add-border {
  border-width: 6px;
  margin-left: -30px;
  width: calc(100% + 60px);
}

.delivery-dropdown {
  position: relative;
  padding: 17px 0;
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  margin-top: 20px;
}

.delivery-dropdown>p {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 0;
}

.delivery-dropdown>p:after {
  position: absolute;
  content: "";
  top: 15px;
  right: 0;
  width: 8px;
  height: 8px;
  border: solid #949494;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

.delivery-dropdown>p>svg {
  margin-right: 10px;
}

.delivery-dropdown>ul {
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  padding: 10px !important;
  background-color: #fff;
  border: 1px solid #eee;
  display: none;
}

.delivery-dropdown>ul>li {
  color: #000;
  font-size: 16px;
}

.delivertype-list {
  margin-top: 15px;
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.delivertype-list>ul {
  width: 2000px;
  display: flex;
}

.delivertype-list li {
  border: 1px solid #C1C1C1;
  border-radius: 4px;
  padding: 15px;
  width: 200px;
  background-color: #f8f8f8;
}

.delivertype-list li:hover {
  border-color: #FFC900;
  background-color: #FFFCF2;
}

.delivertype-list li+li {
  margin-left: 25px;
}

.delivertype-list li>h2 {
  font-weight: 700;
  font-size: 28px;
}

.delivertype-list li>p {
  margin-bottom: 0;
}

.payment-text {
  text-align: center;
  padding: 25px 10px;
}

.payment-text p {
  font-size: 20px;
  font-weight: 700;
  color: #FFC900;
}

.payment-text .btn {
  color: #fff;
  background-color: #FFC900;
  font-size: 20px;
  width: 100px;
  text-transform: capitalize;
  box-shadow: 0 0 0 #0000;
  border: 1px solid #FFC900;
}

.payment-text .btn:hover {
  background-color: #fff;
  color: #FFC900;
}

/* ===================================== trackingtable-form ============================================== */

.trackingtable-tab .nav-pills .nav-link {
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.trackingtable-tab .nav-pills .nav-link.active {
  color: #FFC900;
  font-weight: 600;
}

.trackingtable-tab .nav li+li {
  margin-left: 35px;
}

.trackingtable-tab .tab-content {
  margin-top: 30px;
}

.trackingall-tab>ul {
  display: flex;
  flex-wrap: wrap;
}

.trackingall-tab>ul>li {
  width: calc(100%/2 - 13px);
  margin-bottom: 25px;
  transition: all .3s;


}

.trackingall-tab>ul>li:nth-child(2n+2) {
  margin-left: 25px;
}

.trackingall-tab>ul>li:hover {
  background: #E5E9FF;
  transition: all .3s;
}

.adddpack-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}

.adddpack-box h4 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.adddpack-box p {
  font-size: 12px;
  color: #606060;
}

.adddpack-box .btn {
  color: #fff;
  background-color: #FFC900;
  border: 1px solid #FFC900;
  padding: 15px 10px;
  font-size: 36px;
  line-height: 0;
}

.statusbar-box p {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;

}

.statusbar-box p>b {
  font-weight: 600;
}

.statusbar-box hr {
  margin: 15px 0;
  border-color: #cfcfcf;
  border-width: 2px;
}

.status-text {
  position: relative;
  padding-left: 15px;
  font-weight: 600;
}

.statusbar-box p.status-blue {
  color: #007AFF;
}

.statusbar-box p.status-org {
  color: #FFC900;
}

.statusbar-box p.status-green {
  color: #4BAE4F;
}

.status-text:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.status-blue:after {
  background-color: #007AFF;
}

.status-green:after {
  background-color: #4BAE4F;
}

.status-org:after {
  background-color: #FFC900;
}

.track_order {
  position: relative;
}

.status_bar {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  padding: 10px;
}

.status_bar p {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.status_bar p span {
  color: #FFC900;
  font-weight: 400;
}


.status_address {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}


.status_address .close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #FFC900;
  line-height: 0;
  padding: 15px 20px;

}



/* ============================ callbuyerpopup ============================= */

.callbuyerpopup_outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11111111;
  transition: all .3s;
}

.callbuyerpopup_outer .close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
}

.callbuyerpopup_outer .close-btn svg {
  fill: #fff;
  background-color: #FFC900;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.callbuyerpopup {
  position: absolute;
  top: 50%;
  background-color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  height: 90%;
  max-height: 450px;
  transition: all .3s;
}

.callbuyerpopup h2 {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}

.callbuyer-box {
  background: rgba(255, 201, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  height: calc(100% - 35px);
  overflow-x: auto;
}

.callbuyer-box p {
  margin-bottom: 0;
  word-break: break-all;
}

.callbuyer-box hr {
  margin-left: -15px;
  margin-right: -15px;
}

.remark-box .remarkword {
  word-break: break-all;
}

/* ===============================  Customer part Part =============================== */

.ordertab-sec.customer-sec>.tab-content {
  margin-top: 0;
}

.ordertab-sec.customer-sec .action-btngroup button {
  background-color: #fff;
}

.customer-info {
  display: flex;
  align-items: center;
}

.customer-info>.user-img {
  width: 33px;
}

.customer-info>.user-name {
  width: calc(100% - 33px);
  line-height: normal;
}

/* ================================== addordertag-popup ====================================== */

.addordertag-popup label,
.addordertag-popup .form-control {
  font-size: 14px;
  color: #000;
}

.addordertag-popup .callbuyerpopup {
  height: auto;
}

.addordertag-popup .close-btn svg {
  width: 18px;
  background-color: #fff;
}

.addordertag-popup .btngroups button {
  width: 49%;
}

.addordertag-popup .btngroups {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.addordertag-popup .btngroups button.cancel-btn {
  margin-left: 0;
  margin-right: 5%;
}

/* ================================== cancel order popup ====================================== */

.cancelorder-popup .callbuyerpopup {
  max-height: 260px;
}

.cancelorder-popup .callbuyerpopup h3 {
  font-size: 22px;
  font-weight: 700;

}

.cancelorder-popup .callbuyerpopup p {
  color: #000;
  font-size: 16px;
}

.cancelorder-popup .callbuyerpopup .btngroups button.cancel-btn {
  margin-left: 0;
}

.cancelorder-popup .callbuyerpopup .btngroups {}

.btngroups .accept-btn {
  border: 1px solid #F8485E;
  color: #F8485E;
  margin-left: 15px;
}

/* ------------------------------ Settings page  --------------------- */
.settings-sec .ordertittle-part button,
.backbtn {
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  border: 1px solid #fff;
  color: #000;
  background: #fff;
  transition: all .3s;
}

.backbtn {
  padding: 5px 10px;
}

.settings-sec .ordertittle-part button:hover,
.backbtn:hover {
  transition: all .3s;
  border: 1px solid #ffc900;
}

.settings-sec .ordertittle-part button img {
  width: 30px;
}

.searchWrapper>.chip {
  background-color: #FFDC5A;
  color: #000;
}

.optionContainer>li,
.highlightOption {
  background-color: #ffffff !important;
  color: #000;
}

.multiSelectContainer li:hover {
  background: #0096fb;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
}

.searchWrapper {
  border-radius: 18px !important;
}

.employe-table,
.usersetting-table {
  width: 100%;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  padding-bottom: 15px;
  border-radius: 4px;
  background-color: #fff;
}

.transition-popup .editb2b-box .employe-table {
  overflow: auto;
  max-height: 200px;
}

.employe-table table,
.usersetting-table table {
  width: 100%;
}

.employe-table table th,
.usersetting-table table th {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000;
  padding: 15px 5px;
  border-bottom: 1px solid #bfbfbf;
  line-height: normal;
  margin-bottom: 10px;
}

.employe-table table td,
.usersetting-table table td {
  color: #000;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-height: normal;
  padding: 0 5px;
}

.employe-table table th:first-child,
.employe-table table td:first-child,
.usersetting-table table th:first-child,
.usersetting-table table td:first-child {
  width: 5%;
  max-width: 150px;
}

.employe-table table th:nth-child(2),
.employe-table table td:nth-child(2),
.usersetting-table table th:nth-child(2),
.usersetting-table table td:nth-child(2) {
  width: 15%;
}

.employe-table table th:nth-child(3),
.employe-table table td:nth-child(3),
.usersetting-table table th:nth-child(3),
.usersetting-table table td:nth-child(3) {
  width: 16%;
  text-align: center;
}

.employe-table table th:nth-child(4),
.employe-table table td:nth-child(4),
.usersetting-table table th:nth-child(4),
.usersetting-table table td:nth-child(4) {
  width: 15%;
}

.employe-table table th:nth-child(5),
.employe-table table td:nth-child(5),
.usersetting-table table th:nth-child(5),
.usersetting-table table td:nth-child(5) {
  width: 15%;
}

.employe-table table th:last-child,
.employe-table table td:last-child,
.usersetting-table table th:last-child,
.usersetting-table table td:last-child {
  width: 2%;
  min-width: 100px;
  text-align: center;
}

.settings-box {
  display: flex;
  flex-wrap: wrap;
}

.apexcharts-toolbar {
  display: none !important;
}

.settings-box li {
  width: calc(100%/4 - 34px);
  margin-right: 45px;
  margin-top: 45px;
  padding: 15px;
  border-radius: 8px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  border: 1px solid #fff;
  transition: all .3s;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
}

.settings-box li:nth-child(4n+4) {
  margin-right: 0;
}

.settings-box li:hover {
  border: 1px solid #FFC900;
  transition: all .3s;
}


.settings-box h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin: 15px 0;
}

.settings-box li>img {
  height: 139px;
  object-fit: fill;

}


.popupinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 90%;
  height: 90%;
  max-width: 435px;
  box-shadow: 0 0 10px rgb(0, 0, 0, .5);
  padding: 15px;
  background-color: rgba(255, 255, 255);
  max-height: 490px;
}

.userPermisssionPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 750px;
  box-shadow: 0 0 10px rgb(0, 0, 0, .5);
  padding: 15px;
  background-color: rgba(255, 255, 255);
  max-height: 600px !important;
  margin: 20px;
}

.addcategorypopup .popup_body {
  height: calc(100% - 90px);
  overflow: auto;
  padding-right: 15px;
}

.searchWrapper {
  overflow: overlay;
  height: 200px;
  /* margin-bottom: 100px; */
}


.popupinner h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.popup-body {
  overflow: auto;
  height: calc(100% - 95px);
}

.multiselectblockclass>div>div .displayNone {
  display: block !important;

}

.popupinner .close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupinner .close-btn svg {
  color: #fef;
  font-size: 25px;
  width: 18px;
}

.popupinner label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 8px;
}

.popupinner .remarkname {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  margin-bottom: 8px;
}

.popupinner .form-control,
.popupinner .form-select {
  font-size: 14px;
  color: #000;
  border-radius: 30px;
  background-color: #f2f2f2;
  border: none;
  box-shadow: none;
  margin-bottom: 15px;
}

.popupinner input:placeholder {
  font-size: 12px;
  color: #5f5f5f;
}

/* ==============editb2b-popup =========================== */

.editb2b-popup .popupinner {
  max-height: 700px;
  max-width: 800px;
}


.ticket-description {
  font-size: 14px !important;
  word-wrap: break-word;
}

.data_picker_btn {
  overflow: auto;
  padding: 10px;
  min-height: 100px;
  max-height: 600px
}

/* ==================================== */

.adduser-popup .popupinner {
  max-height: 820px;
  max-width: 700px;

}




.adduser-popup .popupinner .optionListContainer {
  position: relative;
}



.adduser-popup .popup-body {
  height: calc(100% - 100px);
  overflow: auto;
  padding: 0 12px;
}

.adduser-popup input[type=password] {
  padding: .375rem .75rem;
  border-radius: 30px;
}

.adduser-popup .react-tel-input .form-control {
  border-radius: 30px;
}

.adduser-popup .react-tel-input .selected-flag,
.adduser-popup .react-tel-input .flag-dropdown,
.adduser-popup .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 30px 0 0 30px !important;
}

.adduser-popup .react-tel-input .selected-flag:hover,
.adduser-popup .react-tel-input .selected-flag:focus {
  background-color: inherit;
}

.adduser-popup .react-tel-input .country-list {
  width: 180px;
}


/* ======================== userprofile-sec =============================== */

.userprofile-sec .title-bar,
.usersetting-sec .title-bar,
.userdetailpage .title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userprofile-sec .title-bar h2,
.userdetailpage .title-bar h2 {
  font-size: 26px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-right: 35px;
  margin-bottom: 0;
}

.userprofile-sec .title-bar .form-select {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  background-color: rgba(255, 201, 0, 0.1);
  border-radius: 4px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.userprofile-list {
  display: flex;
  flex-wrap: wrap;
}

.userprofile-sec .title-bar .right-part>span {
  margin-right: 15px;
}

.userprofile-sec .title-bar .right-part span .upload-form-control {
  margin-left: 10px;
}

.userprofile-list li {
  width: calc(100%/4 - 23px);
  margin-right: 30px;
  margin-top: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 15px;
  position: relative;
}

.userprofile-list li:nth-child(4n+4) {
  margin-right: 0;
}

.userprofile-list li>.part {
  display: flex;
  flex-wrap: wrap;
}

.userprofile-list li>.part>.left {
  width: 53px;
}

.userprofile-list li>.part>.right {
  width: calc(100% - 53px);
  padding-left: 15px;
}

.userprofile-list li>.part>.right h3 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
  padding-right: 40px;
  word-break: break-all;
}

.userprofile-list li>.part>.right h6 {
  font-size: 18px;
  font-weight: 400;
  color: #b1b1b1;
  margin-bottom: 0;
}

.userprofile-list li>.part>.user-btn {
  position: absolute;
  top: 7px;
  right: 7px;
}

.userprofile-list li>.part>.user-btn button {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.userprofile-list li>.part>.user-btn img {
  width: 14px;
}

.userprofile-list>li>p {
  margin-bottom: 0;
  color: #000;
  margin-top: 10px;
}

.userprofile-list>li>p>a {
  display: block;
  color: #B1B1B1;
  font-size: 14px;
}

.userprofile-list>li>p>a>svg {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}


/* ============================= usersetting page ================================= */

.usersetting-sec .add-btn {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border: 1px solid #fff;
  background-color: #fff;
}

.usersetting-table {
  margin-top: 40px;
}

.usersetting-table button {
  border: none;
}

.employe-table table td {
  padding-top: 10px;
}

.employe-table .action-btngroup button {
  padding: 0;
}

.employe-table .action-btngroup .svg-btn {
  padding: 0;
}

.employe-table .action-btngroup .svg-btn svg {
  width: 15px;
}


/* ======================== userpDetail-sec =============================== */

.userdetailpart {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.userdetailpart>.leftpart {
  width: 43.5%;
  height: 500px;
  border-radius: 20px;
  background: rgba(255, 201, 0, 0.10);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  position: relative;
}

.userdetailbox h3 {
  color: #000;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}

.uploadbtn label {
  display: inline-block;
  background-color: #FFC900;
  font-family: 'Open Sans', sans-serif !important;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
}

.disableButton label {
  display: inline-block;
  background-color: #80808047;
  font-family: 'Open Sans', sans-serif !important;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
}

.userdetailpart>.leftpart .datatxt {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  left: 0;
  padding: 0 0 50px;
}

.userdetailpart>.leftpart .datatxt>p {
  margin: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}

.userdetailpart>.rightpart {
  width: 53%;
}

.userdetailpart>.rightpart>h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0;
}


.userdetailpart .datatable table {
  border-radius: 6px;
  background: #FFF;
  margin: 0;
}

.userdetailpart .datatable table tr {
  display: flex;
  justify-content: space-between;
}

.userdetailpart .datatable table,
.userdetailpart .datatable table th,
.userdetailpart .datatable table td {
  border-collapse: collapse;
}

.userdetailpart .datatable table th,
.userdetailpart .datatable table td {
  padding: 12px 5px;
  border: none;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14pxN;
}

.userdetailpart .datatable table th:first-child,
.userdetailpart .datatable table td:first-child {
  width: 70px;
}

.userdetailpart .datatable table th:nth-child(2),
.userdetailpart .datatable table td:nth-child(2) {
  width: calc(100% - 168px);
}

.userdetailpart .datatable table th:last-child {
  width: 106px;
  text-align: center;
}

.userdetailpart .datatable table td:last-child {
  width: 100px;
  text-align: center;
}

.userdetailpart .datatable table th+th,
.userdetailpart .datatable table td+td {
  border-left: .2px solid #000;
}

.userdetailpart .datatable table thead {
  border-bottom: .2px solid #000;
  text-align: center;
  color: #000;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}


.userdetailpart .datatable table tbody tr:not(:last-child) {
  border-bottom: .2px solid #000;
}

.userdetailpart .datatable table .errorbtn,
.userdetailpart .datatable table .successbtn {
  border: none;
  outline: none;
  background: none;
  font-weight: 700;
}

.userdetailpart .datatable table .successbtn {
  color: #14AE5C;
}

.userdetailpart .datatable table .errorbtn {
  color: #E81313;
}

.userdetailpart .datatable table .errorbtn svg {
  margin-right: 5px;
}

.userdetailpart .datatable table tbody {
  overflow: auto;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.uploadflierange {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.uploadimg {
  width: 56px;
  height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}




.uploadimg>div>img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 56px;
  height: 56px;
  background-color: transparent;
  animation: spinPulse 3s infinite linear;
}

@keyframes spinPulse {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.uploadflierange>.progressbox {
  width: calc(100% - 56px);
  padding-left: 20px;
}

.progresstext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.progresstext h4 {
  margin: 0;
  color: #FFC900;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif !important;
}


.progressbox .progress {
  border-radius: 500px;
  background: #FFF;
  /* background: #FFC900; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset;
  padding: 2px;
  height: 10px;
}

.progressbox .progress .progress-bar {
  border-radius: 500px;
  background: #FFC900;
}




/* ============================ adminsetting page   ============================= */




.adminsetting-sec .title {
  display: flex;
  justify-content: space-between;
}

.adminsetting-sec .title img {
  width: 162px;
}

.adminsetting-sec .title h2 {
  font-size: 26px;
  font-weight: 600;
  color: #000;
  margin-left: 15px;
  margin-top: 10px;
}

.adminform-part {
  max-width: 830px;
  margin-top: 30px;
}

.adminform-part label {
  font-size: 14px;
  color: #000;
  display: block;
  font-weight: 400;
}

.adminform-part span {
  font-size: 14px;
  color: #FFC900;
  display: block;
  font-weight: 400;
}

.adminform-part .form-control {
  font-size: 14px;
  color: #000;
  background: #f0f0f0;
  border-radius: 30px;
  box-shadow: none;
}

.adminform-part .input_filed input {
  padding-left: 10px;
  border-radius: 30px;
}

.order-btn>.dropdown{
  top: 30px !important;
}

.order-btn>.dropdown > .address_all {
  height: auto;
}

.billingicon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.billingicon .download-item { 
  margin-left: 0;
}


/* ========================= Support page =================================== */

.support-page .title {
  display: flex;
}

.support-page .title h2 {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0;
}

.support-page .title .form-select {
  background-color: rgba(255, 201, 0, 0.1);
  color: #FFC900;
  border: 1px solid rgba(255, 201, 0, 0.1);
  font-size: 16px;
  border-radius: 30px;
  width: auto;
  box-shadow: none;
  margin-left: 15px;
  background-position: right 10px center;
  padding: 0px 30px 0px 10px;
}

.sptitle {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.sptitle .select-box {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  position: relative;
  font-size: 14px;

  width: 240px;
  margin-left: 15px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}


.sptitle .form-select {
  background: none;
  border-radius: 30px;
  color: #FFC900;
  border: 1px solid #fff;
  display: block;
  font-size: 14px;
  box-shadow: none;
  width: calc(100% - 70px);
  z-index: 999999;
}


.sptitle .select-box:after {
  position: absolute;
  content: "";
  top: 10px;
  right: 13px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #FFC900;
  border-left: 2px solid #FFC900;
  z-index: 1;
  transform: rotate(-135deg);
}

.displaytext {
  line-height: 260px;
  height: 600px;
  background: #FFCEC2;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.sptitle .select-box+.select-box>span {
  width: 70px;

}

.sptitle .select-box+.select-box .form-select {
  padding-left: 1px;
  width: calc(100% - 70px);
}

.support-list {
  display: flex;
  flex-wrap: wrap;
}

.support-list>li {
  width: calc(100%/2 - 10px);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  position: relative;
}

.support-list>li:nth-child(2n+2) {
  margin-left: 20px;
}

.support-list>li>.left-part {
  display: flex;
  flex-wrap: wrap;
  width: calc(180px);
}

.support-list>li>.left-part>img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}

.support-list>li>.left-part>div>h4 {
  color: #000;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-bottom: 0px;
}

.support-list>li>.left-part>div>h3 {
  color: #000;
  font-weight: 600;
  font-size: 12px;
  margin: 5px 0;
}

.support-list>li>.left-part>div>p {
  color: #ddd;
  font-weight: 400;
}

.support-list>li>.right-part {
  width: calc(100% - 180px);
  padding-left: 15px;
}

.support-list>li>.right-part .dot-btn>svg {
  width: 15px;
}

.star-svg svg {
  width: 20px;
}

.support-list>li>.right-part>p {
  color: #000;
  font-size: 14px;
}

.support-list>li>.right-part>span {
  color: #B4B4B4;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.support-list>li>.right-part .date-text {
  position: absolute;
  right: 50px;
  top: 14px;
}

.support-list>li>.right-part button {
  border: none;
  font-size: 16px;
  color: #000;
  border-radius: 30px;
}

.support-list>li>.right-part .dot-btn {
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 0;
  height: auto;
  line-height: 0;
}

.support-list>li>.right-part .mail-btn,
.support-list>li>.right-part .chat-btn {
  font-size: 14px;
  background: rgba(255, 201, 0, 0.1);
  color: #FFC900;
  padding: 5px 20px;
}

.support-list>li>.right-part .mail-btn svg,
.support-list>li>.right-part .chat-btn svg,
.b2cbtn-box .dismiss-btn svg {
  width: 20px;
}

.support-list>li>.right-part .dismiss-btn {
  border-radius: 30px;
  color: #000000;
  font-size: 14px;
  padding: 5px 20px;
  /* background: rgba(186, 186, 186, 0.3); */
  background-color: #FFC900;
}

.support-list>li>.right-part .chat-btn,
.b2cbtn-box .dismiss-btn+.dismiss-btn {
  margin-left: 10px;
}

.b2cbtn-box {
  display: flex;
  justify-content: space-between;
}



.add_tag_ex {
  font-size: 12px;
  color: rgb(197, 192, 192);
  /* text-shadow: 0 0 0 #fff; */
}

/*  ---------------------- invoice page --------------------------- */
.invoice-sec>.title {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px;
  align-items: center;

}

.invoice-sec>.title>h2 {
  color: #000;
  font-size: 32px;
  margin-bottom: 0;
  font-weight: 600;
}

.invoice-sec>.title .form-control {
  height: 30px;
}

#cod-tab-pane>ul li {
  width: calc(100%/4 - 27px);
}

#cod-tab-pane>ul li>span>span {
  color: #bbb;
  font-weight: 400;
  font-size: 10px;
}


.invoice-tab .nav {
  border-bottom: 1px solid #C5C5C5;
}

.invoice-tab .nav button {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  padding: 10px 15px;

}

.invoice-tab .nav button.active {
  background-color: #FFC900;
  color: #fff;
  font-weight: 600;
  border-bottom: 1px solid #C5C5C5;
}


.cod-table,
.invoice-table {
  margin-top: 40px;
  background-color: #fff;
  box-shadow: 0 0px 5px rgb(0 0 0 / 10%);
  padding-bottom: 20px;
  border-radius: 5px;
}

.tab-content .cod-table th,
.tab-content .invoice-table th {
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  border-bottom: 1px solid #C5C5C5;
}

.tab-content .cod-table table td,
.tab-content .invoice-table td {
  padding: 0px 15px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  border-top: none;
  padding-top: 15px;
}

#cod-tab-pane .cod-table table tr td:first-child,
#cod-tab-pane .cod-table table tr th:first-child {
  width: 7%;
}

#cod-tab-pane .cod-table table tr td:nth-child(2),
#cod-tab-pane .cod-table table tr th:nth-child(2) {
  width: 10%;
  text-align: center;

}

#cod-tab-pane .cod-table table tr td:nth-child(3),
#cod-tab-pane .cod-table table tr th:nth-child(3) {
  width: 10%;
}

#cod-tab-pane .cod-table table tr td:nth-child(4),
#cod-tab-pane .cod-table table tr th:nth-child(4) {
  text-align: center;
  width: 12%;
}

#cod-tab-pane .cod-table table tr td:nth-child(5),
#cod-tab-pane .cod-table table tr th:nth-child(5) {
  width: 12%;
  text-align: center;
}


#cod-tab-pane .cod-table table tr td:last-child,
#cod-tab-pane .cod-table table tr th:last-child {
  width: 2%;
  min-width: 100px;
}

/* invoice-tab-pane  table */


#invoice-tab-pane>ul,
#cod-tab-pane>ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 25px;
}

#invoice-tab-pane>ul li,
#cod-tab-pane>ul li {
  background: #EBF3FB;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  padding: 15px;
}

#invoice-tab-pane>ul li {
  width: calc(100%/4 - 28px);
}

#invoice-tab-pane>ul li+li,
#cod-tab-pane>ul li+li {
  margin-left: 35px;
}

#invoice-tab-pane>ul li>span,
#cod-tab-pane>ul li>span {
  display: block;
  margin-bottom: 5px;
}

#invoice-tab-pane .invoice-table table tr td:first-child,
#invoice-tab-pane .invoice-table table tr th:first-child {
  width: 7%;
}

#invoice-tab-pane .invoice-table table tr td:nth-child(2),
#invoice-tab-pane .invoice-table table tr th:nth-child(2) {
  width: 10%;
  text-align: center;

}

#invoice-tab-pane .invoice-table table tr td:nth-child(3),
#invoice-tab-pane .invoice-table table tr th:nth-child(3) {
  width: 10%;
}

#invoice-tab-pane .invoice-table table tr td:nth-child(4),
#invoice-tab-pane .invoice-table table tr th:nth-child(4) {
  text-align: center;
  width: 12%;
}

#invoice-tab-pane .invoice-table table tr td:nth-child(5),
#invoice-tab-pane .invoice-table table tr th:nth-child(5) {
  width: 12%;
  text-align: center;
}

#invoice-tab-pane .invoice-table table tr td:nth-child(6),
#invoice-tab-pane .invoice-table table tr th:nth-child(6) {
  width: 12%;
  text-align: center;
}

#invoice-tab-pane .invoice-table table tr td:last-child,
#invoice-tab-pane .invoice-table table tr th:last-child {
  width: 2%;
  min-width: 100px;
}

/* --------------------------------------------------------- */
.wallet-part {
  display: flex;
  padding-top: 30px;
}

.wallet-part>.left-part {
  width: 50%;
  padding-right: 15px;
}

.wallet-part>.left-part h2 {
  font-size: 28px;
  font-weight: 600;
}

.wallet-part>.left-part>h4 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 40px;
}

.wallet-popup .amout {
  background: #FFFCF2;
  border-radius: 10px;
  margin: 15px 0;
  padding: 10px;
}

.wallet-popup .amout p {
  font-size: 14px;
  color: #000;
  letter-spacing: 0.2;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.wallet-popup .amout>div {
  position: relative;
  padding: 10px 0;
  color: #000;
}

.wallet-popup .amout>div>p {
  color: #000;
  margin-bottom: 0;
  position: absolute;
  top: 27px;
  left: 8px;
}

.wallet-popup .amout>div .form-control {
  background: #FCFEFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #000;
  padding: 18px 35px;
  font-size: 14px;
  margin-bottom: 0;
}

.wallet-popup .amout>div>span {
  position: absolute;
  top: 26px;
  right: 10px;
  cursor: pointer;
}

.wallet-popup .amout>span {
  font-size: 10px;
  color: #9B9B9B;
}

.addmoney-part .amout>ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.addmoney-part .amout>ul li {
  cursor: pointer;
  border: 1px solid #DADADA;
  border-radius: 100px;
  background-color: #fff;
  font-size: 14px;
  padding: 4px 8px;
  line-height: normal;
  margin-bottom: 5px;
  color: #7e7e7e;
}

.addmoney-part .amout>ul li+li {
  margin-left: 12px;
}

.wallet-part>.right-part {
  width: 50%;
  padding-left: 15px;
}

.profileview_popupChat .popup-body {
  height: calc(100% - 40px);
}

.profileview_popupChat .trh-box {
  height: 350px;
}

.trh-box {
  background: #FFFFFF;
  border-radius: 14px;
  padding-top: 25px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  color: #6b6b6b;
  font-size: 16px;
  overflow: auto;
  margin: 10px 0 0 0;
  min-height: 100px;
}


.profileview_popupChat .popupinner {
  height: 90%;
  max-height: 550px;
  /* max-height: 80%; */
}

.profileview_popupChat .upload-form-control {
  width: 30px;
  height: 30px;
  padding: 0;
  display: block;
  margin: 10px auto;
}

.image-container {
  width: 300px;
  /* adjust the width as needed */
  height: 300px;
  /* adjust the height as needed */
  /* overflow: hidden; */
}

.image-container img {
  width: 100%;
  height: 100%;
  margin-left: 50%;
  object-fit: cover;
}

.profileview_popupChat .popupinner textarea.form-control {
  margin-bottom: 0;
}

.trh-box h4 {
  font-size: 20px;
  padding: 0 25px 23px;
  font-weight: 600;
  height: 54px;
  margin-bottom: 0;
}

.trh-box p {
  color: #6b6b6b;
  font-size: 14px;
  padding: 0 25px;
}

.trh-box div .text-black {
  color: #000;
  font-weight: bold;
}

.trh-box div .text-yellow {
  color: #FF7D00;
  font-weight: bold;
}

.trh-box div .text-green {
  color: green;
  font-weight: bold;
}

.trh-box div .text-game {

  color: #08D110;
  background: rgba(8, 209, 16, 0.2);
  border-radius: 10px;
  font-weight: 600;
  border: 1px solid rgba(8, 209, 16, 0.2);
  font-size: 10px;
  padding: 2px 6px;
}


.trh-box div .text-orange {
  color: #FF7D00;
  font-weight: 600;
  font-size: 10px;
  background: rgba(255, 125, 0, 0.2);
  border: 1px solid rgba(255, 125, 0, 0.2);
  border-radius: 10px;
  padding: 2px 6px;

}

.trh-box div .text-danger {
  color: rgb(230, 80, 80);
  font-weight: 600;
  font-size: 10;
  background: rgba(255, 50, 0, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(255, 50, 0, 0.2);
  font-size: 10px;
  padding: 2px 6px;
}



.trh-box div .text-red {
  font-weight: bold;
  color: red;
}

.trh-box>ul {
  margin-bottom: 0;
  height: calc(100vh - 195px);
  overflow: auto;
}

.trh-box li>div {
  padding: 15px 25px;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.trh-box li {
  margin-bottom: 25px;
}

/*================= ordertrack-part  =====================*/

.ordertrack-part {
  display: flex;
  justify-content: space-between;
}

.ordertrack-part>.left-part {
  width: 39%;
}

.ordertrack-part>.right-part {
  width: 59%;
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  height: 100%;
  background: #FFC900;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ordertrack-part>.details-right-part {
  width: 59%;
}

.track-list {
  position: relative;
  background: #fff;
  padding: 25px 25px 25px !important;
  border-radius: 15px;
  box-shadow: 0 0 10px #eee;
  max-width: 520px;
  margin: 0 auto 0 30px;
}

.track-list:before {
  position: absolute;
  content: "";
  top: 31px;
  left: 38px;
  width: 4px;
  background-color: #d9d9d9;
  height: calc(100% - 80px);
}

.track-list>li {
  position: relative;
  padding-left: 40px;
  padding-bottom: 30px;
}

.active_DELIVERED {
  padding-bottom: 0px !important;
}

.track-list>li.active_DELIVERED:before {
  height: 65% !important
}

.track-list>li:last-child {
  padding-bottom: 0;
}

.track-list>li>span {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  left: 0;
  top: 0px;
  background-color: #d9d9d9;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-list>li:first-child>span {
  top: 0;
}

.track-list>li:last-child>span {
  top: auto;
  bottom: 8px;
}

.track-list>.activeRTO_DELIVERED:last-child>span {
  top: auto;
  bottom: -3px !important;
}

.active_DELIVERED+li {
  display: none
}

.track-list>li .trackdate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 380px;
}

.track-list>li .trackdate>span:first-child {
  font-weight: 700;

}

.track-list>li>p {
  color: #979797;
  font-size: 14px;
  margin-bottom: 5px;

}

.track-list>li>h4 {
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.track-list>li.active>span {
  background-color: #FFC900;
}

.track-list>li>span>svg {
  fill: #727272;
}

.track-list>li.active>span>svg {
  fill: #fff;
}

.track-list>li.active:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 13px;
  width: 4px;
  background-color: #FFC900;
  height: 110%;
  z-index: 1;
}

.track-list>li.activeRTO_DELIVERED:before,
.track-list>li.active_RTO:before {
  height: 100% !important;
}


.track-list>li.active:first-child::after {
  height: 0;
}

.userinfo-body .form-control option:checked,
.userinfo-body .form-control option:hover {
  box-shadow: 0 0 10px 100px red inset;
}

.itemtrackinfo>a {
  font-weight: 500;
  color: #ffc900 !important;
  display: inline-block;
  margin: 0 0 10px;
  line-height: normal;
}

.itemtrackinfo li+li {
  margin-top: 20px;
}

.itemtrackinfo li>p {
  margin-bottom: 0;
  padding-bottom: 5px;
  word-wrap: break-word;
}

.itemtrackinfo li>p>b {
  color: gray;
}

/* ============== feedback-popup  ================ */

.feedback-popup .popupinner {
  max-width: 700px;
  max-height: 365px;
  padding: 30px 30px 50px;
}

.feedback-popup .popup-body {
  padding: 25px 25px 10px;
  border: 1px solid #d9d9d9;
  height: 100%;
}

.feedback-popup h6 {
  color: #000;
  font-size: 14px;
  line-height: 25px;
}

.feedback-popup p {
  color: #B4B4B4;
  font-size: 16px;
}


/* ============================ raised-popup =========================== */
.raised-popup .popupinner {
  max-height: 465px;
}

.raised-popup .popup-body {
  height: calc(100% - 38px);
}

.raised-popup .popupinner textarea {
  height: 200px;
  padding: 15px;
}

.raised-popup .popupinner .save-btn {
  background-color: #FFC900;
  color: #fff;
  border-radius: 100px;
  font-weight: 600;
  padding: 8px 45px;
}


/* ======================== sharefeedback-popup ====================== */

.sharefeedback-popup .popupinner {
  max-height: 400px;
}

.sharefeedback-popup h4 {
  color: #FFC900;
}

.sharefeedback-popup p {
  color: #4F4F4F;
  font-weight: 400;
}

.sharefeedback-popup textarea.form-control {
  height: 150px;
  padding: 15px;
  border-radius: 0;
  margin: 25px 0;
}

.sharefeedback-popup button.save-btn {
  background-color: #FFC900;
  color: #fff;
  border-radius: 100px;
  font-weight: 600;
  padding: 8px 45px;
}


/* ========================= sharefeedback-popup =================================== */

.query-popup .popupinner {
  max-width: 450px;
  max-height: 530px;
}


.query-popup .popupinner .title {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 10px;
}

.query-popup .popupinner .title>.left-part {
  width: 48%;
  display: flex;
}

.query-popup .popupinner .title>.left-part img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.query-popup .popupinner .title>.left-part h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.query-popup .popupinner .title>.left-part p {
  font-size: 12px;
  margin-bottom: 0;
  color: #9d9d9d;
}

.query-popup .popupinner .title>.right-part {
  width: 48%;
  text-align: right;
}

.query-popup .popupinner .title>.right-part p {
  font-size: 12px;
  color: #000;
  margin-bottom: 5px;
}

.query-popup .popupinner .title>.right-part span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #9d9d9d;
  margin-bottom: 5px;
}

.query-popup .popupinner .title>.right-part span>a {
  color: #9d9d9d;
}

.query-popup .popupinner>p {
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 600;
}

.query-popup .popupinner .popup-body {
  height: calc(100% - 220px);
}

.query-popup .popupinner .popup-body>p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.query-popup button {
  border: none;
  font-size: 16px;
  color: #000;
  border-radius: 30px;
}

.query-popup .dot-btn {
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 0;
  height: auto;
  line-height: 0;
}

.query-popup .mail-btn,
.query-popup .chat-btn {
  font-size: 14px;
  background: rgba(255, 201, 0, 0.1);
  color: #FFC900;
  padding: 5px 20px;
}

.query-popup .mail-btn,
.query-popup .chat-btn {
  font-size: 14px;
}

.query-popup .mail-btn svg,
.query-popup .chat-btn svg,
.query-popup .dismiss-btn svg {
  width: 20px;
}

.query-popup .mail-btn,
.query-popup .chat-btn,
.query-popup .dismiss-btn {
  padding: 5px 15px;
}

.query-popup .dismiss-btn {
  border-radius: 30px;
  color: #929292;
  font-size: 14px;
  padding: 5px 20px;
  background: rgba(186, 186, 186, 0.3);
}

/* ===================  download invoice popup ============================ */

.downloadinvoice-popup .popupinner {
  max-height: 250px;
  padding: 30px;
}

.downloadinvoice-popup .btngroups button.save-btn {
  background-color: #FFC900;
  color: #000;
  font-weight: 600;
  width: 100%;
  border-radius: 30px;
  display: block;
  margin-top: 50px;
}


.track-btn {
  border: 1px solid #FFC900;
  background-color: #FFC900;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  border-radius: 3px;
  margin-left: 10px;
  padding: 2px 10px 2px 10px;
}

/*----------------------- deliveryaction-popup  --------------*/

.deliveryaction-popup .popupinner {
  max-height: 350px;
  max-width: 412px;
  text-align: center;
}

.remark-popup .popupinner {
  max-height: 350px;
  max-width: 412px;
}

.deliveryaction-popup .popupinner h2,
.deliverypartneraction-popup .popupinner h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: #000;
  margin: 15px 0;
}

.deliveryaction-popup .popupinner p {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}

.deliveryaction-popup .popup-body,
.deliverypartneraction-popup .popup-body {
  height: calc(100% - 75px);
}

/* ------------------deliverypartneraction-popup--------- */
.deliverypartneraction-popup .popupinner {
  max-height: 350px;
  max-width: 412px;
}

/* .chat .left{
    float: left;
    clear: both;
  }
  .chat .right{
    float: right;
    clear: both;
  } */
.chat .left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.chat .right {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.usercomment-box .screenshot {
  height: 40px
}

/* -------------------- remarkgroups------------*/


.tracking-popup .popup-body {
  height: calc(100% - 55px);
}

.tracking-popup .popup-body textarea {
  border-radius: 9px;
  background: #FAFAFA;
  border: none;
}

.tracking-popup .popup-body .btngroups,
.remarkgroups {
  margin: 25px 0 0;
  text-align: right;
}

.remarkgroups {
  margin: 25px 0 0;
  text-align: center;
}

/* trackorderstatuslogin */

.trackorderstatuslogin>.left-part {}

.trackorderstatuslogin>.left-part>.track-list {
  margin-left: 0;
  /* overflow: auto; */
  /* height: calc(100vh - 190px); */
}

.trackorderstatuslogin>.right-part {
  width: 52%;
  height: calc(100vh + 30%);
}



/* ------------------------------ Responsive css --------------------- */

@media(max-width:1400px) {
  .title-part {
    padding: 10px 0;
  }

  .title-part h3 {
    font-size: 16px;
  }

  .adminorder-list li+li {
    margin-left: 20px;
  }

  .adminorder-list li h6 {
    font-size: 16px;
  }

  .adminorder-list li {
    display: block;
    text-align: center;
  }

  .adminorder-list li figure {
    margin: 0 auto;
  }

  .adminorder-list li figure img {
    width: 70%;
  }

  .adminorder-list li h3 {
    font-size: 25px;
  }

  .adminorder-list li>div {
    width: auto;
  }

  .adminorder-list li h6 {
    margin: 15px 0 5px;
  }

  .ordernumber-part>.left-part {
    width: 63%;
  }

  .ordernumber-part>.right-part {
    width: 36%;
  }

  .trackingtable-tab .nav li+li {
    margin-left: 15px;
  }

}

@media(max-width:1199.50px) {

  .tab-content table th,
  .tab-content>div>table th,
  .tab-content .cod-table th,
  .tab-content .invoice-table th {
    font-size: 14px;
  }

  .tab-content .cod-table th,
  .tab-content .invoice-table th,
  .tab-content .cod-table td,
  .tab-content .invoice-table td {
    padding: 10px 5px;
  }

  .tab-content table td {
    font-size: 13px;
  }

  .tab-content table td .btn-ship {
    width: 75px;
    font-size: 11px;
  }

  .ordertab-sec .tab-content .tab-pane,
  .ordertab-sec.customer-sec .tab-content>div,
  .employe-table,
  .usersetting-table,
  .invoice-table,
  .cod-table {
    overflow-x: auto;
    width: 100%;
  }

  .ordertab-sec .tab-content .tab-pane table,
  .ordertab-sec.customer-sec .tab-content table,
  .employe-table table,
  .usersetting-table table,
  .invoice-table table,
  .cod-table table {
    width: 1000px;
  }

  .delivery-box img {
    width: 60px;
  }

  .summery-table table th,
  .summery-table table td,
  .customer-part ul li h4,
  .order-box li h5,
  .delivery-box h5,
  .customer-part ul li p,
  .order-box li p {
    font-size: 12px;
  }

  .summery-table table th>b,
  .customer-part h2,
  .delivery-box h2,
  .order-box li h2 {
    font-size: 14px;
  }

  .delivery-box .btn {
    font-size: 12px;
    padding: 5px 14px;
  }

  .orderinfo-header h2 {
    font-size: 25px;
    width: calc(100% - 186px);
  }

  .trakingright_part {
    margin-top: 25px;
  }

  .settings-box h3 {
    font-size: 20px;
  }

  .settings-box li {
    width: calc(100%/4 - 23px);
    margin-right: 30px;
    margin-top: 30px;
  }

  .userprofile-list li {
    width: calc(100%/3 - 20px);
  }

  .userprofile-list li:nth-child(4n+4) {
    margin-right: 30px;
  }

  .userprofile-list li:nth-child(3n+3) {
    margin-right: 0;
  }

  .userprofile-list>li>p>a {
    font-size: 14px;
  }

  .support-list>li>.right-part {
    width: 100%;
    padding-left: 0;
  }

  .support-list>li>.left-part {
    width: 100%;
    padding-right: 30px;
  }

  #invoice-tab-pane ul li {
    font-size: 16px;
    width: calc(100%/4 - 20px);
  }

  #invoice-tab-pane ul li+li {
    margin-left: 42px;
  }

  .employe-table table td,
  .usersetting-table table td {
    font-size: 14px;
  }

  .dashboardcontent-title h2 {
    font-size: 28px;
  }

  .meter-box .admin-box:first-child,
  .rev-box .admin-box:first-child {
    width: 49%;
  }

  .meter-box .admin-box:last-child,
  .rev-box .admin-box:last-child {
    width: 49%;
  }

  .transaction-table {
    overflow: auto;
  }

  .transaction-table table {
    width: 700px;
  }

  .ordertrack-part .summery-table {
    overflow: auto;
  }

  .ordertrack-part .summery-table table {
    width: 570px;
  }

  .dashboardcontent-title .filter-part {
    top: 0;
  }


  .userprofile-sec .title-bar .left-part h2 {
    margin-right: 10px;
    font-size: 22px;
  }

  .userprofile-sec .title-bar .upload-form-control {
    margin-left: 5px;
  }

  .transition-popup .editb2b-box .employe-table table {
    width: 100%;
    min-width: 400px;
  }



}

@media(max-width:991px) {
  .dashboardcontent-part {
    flex-wrap: wrap;
  }

  .dashboardcontent-part>.left-part {
    width: 100%;
  }

  .dashboardcontent-part>.right-part {
    width: 100%;
    margin-top: 25px;
  }

  .adminheader-bar>.left-part {
    width: 300px;
  }

  .ordernumber-part>.right-part {
    width: 100%;
    margin-top: 25px;
  }

  .ordernumber-part>.left-part {
    width: 100%;
  }

  .settings-box h3 {
    font-size: 16px;
  }

  .settings-box li {
    width: calc(100%/3 - 20px);
    margin-right: 30px;
    margin-top: 30px;
  }

  .settings-box li:nth-child(4n+4) {
    margin-right: 30px;
  }

  .settings-box li:nth-child(3n+3) {
    margin-right: 0;
  }

  .user-list li>span {
    width: 30px;
    height: 30px;
  }

  .user-list li>span:after {
    top: 6px;
    left: 5px;
    width: 15px;
    height: 15px;
  }

  .user-list:after {
    top: -2px;
    transform: none;
    left: 8%;
  }

  .user-list li {
    font-size: 11px;
  }

  .user-list li.active>span:before,
  .user-list li.active:last-child>span:before {
    width: 0;
  }

  .userinfo-body .form-box span {
    width: 20px;
    font-size: 8px;
  }

  .userinfo-body .form-box input {
    padding-right: 20px;
  }

  .userprofile-list li:nth-child(3n+3) {
    margin-right: 15px;
  }

  .userprofile-list li {
    margin-right: 15px;
    width: calc(100%/2 - 10px);
  }


  .userprofile-list li:nth-child(2n+2) {
    margin-right: 0px;
  }

  .adminform-part label,
  .adminform-part span {
    font-size: 12px;
  }

  .support-list>li>.right-part .mail-btn,
  .support-list>li>.right-part .chat-btn {
    font-size: 14px;
  }

  .support-list>li>.right-part .mail-btn,
  .support-list>li>.right-part .chat-btn,
  .support-list>li>.right-part .dismiss-btn {
    padding: 5px 15px;
  }

  .support-list>li>.right-part .dismiss-btn {
    margin-top: 10px;
  }

  #invoice-tab-pane>ul li,
  #cod-tab-pane>ul li {
    font-size: 16px;
  }

  #invoice-tab-pane>ul,
  #cod-tab-pane>ul {
    margin-top: 0;
  }

  #invoice-tab-pane>ul li {
    width: calc(100%/3 - 28px);
    margin-top: 15px;
  }

  #invoice-tab-pane>ul li:nth-child(3n+1) {
    margin-left: 0;
  }

  .invoice-tab .nav button {
    padding: 8px 10px;
  }

  #cod-tab-pane>ul li {
    width: calc(100%/2 - 10px);
    margin-top: 20px;
  }

  #cod-tab-pane>ul li+li {
    margin-left: 0px;
  }

  #cod-tab-pane>ul li:nth-child(2n+2) {
    margin-left: 20px;
  }

  .privacy-banner .container:after {
    background-image: none;
  }

  .userprofile-list li>.part>.right h3 {
    font-size: 16px;
    padding-right: 25px;
  }

  .userprofile-list>li>p>a {
    font-size: 12px;
  }

  .wallet-part {
    padding-top: 20px;
    flex-wrap: wrap;
  }

  .wallet-part>.left-part {
    width: 100%;
    padding-right: 0;
  }

  .wallet-part>.right-part {
    width: 100%;
    padding-left: 0;
  }

  .rev-box,
  .meter-box {
    flex-wrap: wrap;
  }

  .meter-box .admin-box:first-child,
  .rev-box .admin-box:first-child {
    width: 100%;
  }

  .meter-box .admin-box:last-child,
  .rev-box .admin-box:last-child {
    width: 100%;
  }

  .rev-box {
    flex-direction: column-reverse;
  }

  .ordertrack-part {
    flex-wrap: wrap;
  }

  .ordertrack-part>.left-part {
    width: 100%;
  }

  .ordertrack-part>.right-part {
    display: none;
  }

  .ordertrack-part>.details-right-part {
    width: 100%;
    margin-top: 30px;
  }

  .ordertrack-part .summery-table table {
    width: 645px;
  }

  .track-list li+li {
    /* padding-top: 30px; */
  }

  .dashboardcontent-title h2 {
    font-size: 20px;
    min-width: 160px;
  }

  .dashboardcontent-title>div {
    width: calc(100% - 160px);
  }

  .dashboardcontent-title .ordertittle-part {
    margin-right: 25px;
  }

  .ordertittle-part>ul .form-group {
    width: 100%;
  }

  .ordertittle-part>ul {
    width: 100%;
  }

  .profileview_popup .upload-form-control {
    margin-left: 8px;
  }

  .userprofile-sec .title-bar {
    flex-wrap: wrap;
    position: relative;
  }

  .userprofile-sec .title-bar>.left-part {
    margin-bottom: 10px;
    width: 100%;
  }

  .userprofile-sec .title-bar>.right-part .backbtn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .trackorderstatuslogin>.left-part>.track-list {
    max-width: 100%;
  }

}


@media (min-width: 768px) {
  .leftPartDisplayBlock {
    display: block !important;
  }
}

@media(max-width:767.50px) {
  .filter-body {
    margin-top: 52px;
    height: calc(100vh - 52px);
  }


  .admin-toggle .toggle-icon {
    width: 30px;
    height: 25px;
    cursor: pointer;
    position: relative;
  }

  .admin-toggle {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    /* display: none;  */
    z-index: 11111111;
    align-items: center;
  }

  .admin-toggle .toggle-icon span {
    position: absolute;
    left: 0;
    background-color: #000;
    display: block;
    height: 5px;
    width: 100%;
    border-radius: 10px;
    left: 0;

  }


  .admin-toggle .toggle-icon span:first-child {
    top: 0;
  }

  .admin-toggle .toggle-icon span:nth-child(2) {
    width: 50%;
    top: 44%;
  }

  .admin-toggle .toggle-icon span:last-child {
    bottom: 0px;
  }

  .admin-toggle .mobile-logo {
    margin-left: 10px;
  }

  .adminlogo>.full-logo {
    display: none;
  }

  .dashboard-part>.left-part {
    /* width: 60px; */
    min-height: auto;
    margin-top: 0px;
    position: absolute;
    z-index: 111111111;
    height: calc(100vh - 53px);
    /* display: none; */
  }

  .adminlogo {
    display: none;
  }

  .dashboard-part>.left-part>.adminmenu {
    margin-top: 15px;
  }



  .menu_sm_show.dashboard-part:after {
    position: absolute;
    top: 52px;
    left: 0;
    content: "";
    width: 100%;
    height: calc(100% - 52px);
    background-color: rgb(0 0 0 / 50%);
    z-index: 11111111;
  }

  .short-logo {
    display: inline-block;
    transition: all .3s;
  }

  .content-sec {
    height: calc(100vh - 52px);
    width: calc(100% - 60px);
    width: 100%;
  }

  .adminheader-bar {
    width: 100%;
    padding-left: 170px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 15px 10px 70px;
  }

  .searchicon {
    display: inline-block;
  }

  .adminheader-bar>.left-part {
    /* width: calc(100% - 60px); */
    width: 100%;
    /* max-width: 500px; */
    position: absolute;
    top: 52px;
    left: 0px;
    background: #fff;
    padding: 15px 5px;
    box-shadow: 0 5px 10px rgb(0 0 0 / .1);
    display: none;
  }

  .adminheader-bar>.left-part .form-group>ul {
    box-shadow: none;
    top: 36px;
  }

  .adminheader-bar>.right-part>ul .user-part {
    width: auto;
  }

  .adminheader-bar>.right-part>ul .user-part>.user-info {
    display: none;
  }

  .adminheader-bar.formsmshow>.left-part {
    display: block;
  }

  .summery-table {
    width: 100%;
    overflow-x: auto;
  }

  .summery-table table {
    min-width: 450px;
  }

  .dashboard-part>.left-part::-webkit-scrollbar,
  .content-sec::-webkit-scrollbar,
  .select-items::-webkit-scrollbar,
  .filter-body::-webkit-scrollbar,
  .data_picker .rdrDateRangePickerWrapper::-webkit-scrollbar,
  .ordertab-sec .tab-content .tab-pane::-webkit-scrollbar,
  .summery-table::-webkit-scrollbar,
  .delivertype-list>ul::-webkit-scrollbar,
  .editpopup::-webkit-scrollbar,
  .ordertab-sec.customer-sec .tab-content>div::-webkit-scrollbar,
  .editb2b-box .popup-body::-webkit-scrollbar,
  .employe-table::-webkit-scrollbar,
  .usersetting-table::-webkit-scrollbar,
  .invoice-table::-webkit-scrollbar,
  .cod-table::-webkit-scrollbar,
  .transaction-table::-webkit-scrollbar,
  .notification-box>ul::-webkit-scrollbar,
  .editpopup-body::-webkit-scrollbar,
  .callbuyer-box::-webkit-scrollbar,
  .adduser-popup .popup-body::-webkit-scrollbar,
  .wallet-popup .popup-body::-webkit-scrollbar,
  .trh-box>ul::-webkit-scrollbar,
  .popup-body::-webkit-scrollbar,
  .adminmenulist::-webkit-scrollbar,
  .editb2b-box::-webkit-scrollbar,
  .addcategorypopup .popup_body::-webkit-scrollbar,
  .trh-box::-webkit-scrollbar,
  .trackorderstatuslogin>.left-part>.track-list::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .orderinfo-header h2 {
    font-size: 20px;
  }

  .ordertittle-part {
    padding: 10px 0 30px;
  }

  .ordertittle-part h2 {
    font-size: 26px;
    margin-right: 10px;
  }

  .ordertittle-part>ul .add-item,
  .ordertittle-part>ul .download-item,
  .ordertittle-part>ul>li.form-control {
    margin-left: 10px;
  }

  .dashboardcontent-title h2 {
    display: none;
  }

  .dashboardcontent-title>div {
    width: 100%;
  }

  .dashboardcontent-title .ordertittle-part>ul {
    width: 100%;
  }


  .ordertittle-part>ul .form-group {
    width: 100%;
  }

  .trackingall-tab>ul>li {
    width: 100%;
  }

  .trackingall-tab>ul>li:nth-child(2n+2) {
    margin-left: 0;
  }

  .customer-inner .ordertittle-part {
    flex-wrap: nowrap;
  }

  .editb2b-box .popup-body {
    overflow-y: auto;
    max-height: 480px;
    overflow-x: hidden;
  }

  .userinfo-body h3 {
    font-size: 16px;
  }

  .userprofile-list li {
    margin-right: 15px;
    width: calc(100%/2 - 10px);
  }

  .userprofile-list li:nth-child(4n+4),
  .userprofile-list li:nth-child(3n+3) {
    margin-right: 15px;
  }

  .userprofile-list li:nth-child(2n+2) {
    margin-right: 0;
  }

  .userprofile-list li>.part>.right h3 {
    font-size: 16px;
  }

  .userprofile-list li>.part>.right h6 {
    font-size: 14px;
  }

  .b2cbtn-box {
    display: block;
  }

  .b2cbtn-box>div {
    margin-bottom: 10px;
  }

  #invoice-tab-pane>ul li {
    width: calc(100%/3 - 17px);
    margin-top: 15px;
  }

  #invoice-tab-pane>ul {
    margin-top: 0;
  }

  #invoice-tab-pane>ul>li:nth-child(3n+1) {
    margin-left: 0;
  }

  .invoice-sec>h2 {
    font-size: 25px;
    margin-bottom: 25px;
  }

  /* ========================== notification-box =============================== */

  .notification-box {
    top: 52px;
    height: calc(100vh - 52px);
  }

  .notification-box .title h2 {
    font-size: 20px;
  }

  .notification-box .title .btn svg {
    width: 20px;
  }

  .popup-box {
    top: 102px;
    height: calc(100vh - 102px);
  }

  .popup-box h2 {
    font-size: 16px;
    padding: 15px 40px 15px 20px;
  }

  .pacform-box .form-control {
    padding-left: 5px;
    padding-right: 20px;
    font-size: 14px;
  }

  .pacform-box>span {
    top: 0px;
    font-size: 10px;
    width: 20px;
  }

  .editpopup h3 {
    font-size: 16px;
  }

  .gen-btn {
    margin-top: 15px;
  }

  .filterbtn-group {
    margin: 25px 0 10px;
    position: relative;
    width: 100%;
  }

  .dashboardcontent-title h2 {
    font-size: 25px;
  }

  .wallet-part>.left-part h2 {
    font-size: 20px;
  }

  .wallet-part>.left-part>h4 {
    font-size: 20px;
  }

  .trh-box h4 {
    padding: 0 15px 25px;
  }

  .trh-box p {
    padding: 0 10px;
  }

  .trh-box li>div {
    padding: 15px 0;
  }

  #invoice-tab-pane ul li+li {
    margin-left: 25px;
  }

  .adminheader-bar>.right-part {
    width: 100%;
  }

  .adminheader-bar>.right-part>ul {
    width: 100%;
    justify-content: end;
  }

  .adminheader-bar>.right-part>ul li:first-child>div {
    display: none;
  }

  .adminheader-bar>.right-part>ul .user-part .dropdown-menu .dropdown-item {
    font-size: 14px;
  }

  .adminheader-bar>.right-part>ul .user-part .dropdown-menu>li {
    font-size: 14px;
  }

  .uploadLabel>div {
    height: 20px;
  }

  .adminheader-bar>.right-part>ul .user-part img {
    margin-right: 0;
  }

  .editb2b-box .close-btn,
  .addordertag-popup .close-btn {
    width: 30px;
    height: 30px;
  }

  .editb2b-box .close-btn svg,
  .addordertag-popup .close-btn svg {
    width: 14px;
  }

  .track-list {
    margin: 0 auto;
  }

  .userdetailpart {
    flex-wrap: wrap;
  }

  .userdetailpart {
    margin-top: 20px;
  }

  .userdetailpart>.leftpart {
    width: 100%;
    height: 320px;
  }

  .userdetailpart>.leftpart .datatxt {

    padding: 0 0 20px;
  }

  .userdetailpart>.rightpart {
    width: 100%;
  }


}


@media(max-width:580.50px) {

  .adminorder-list {
    flex-wrap: wrap;
  }

  .adminorder-list li {
    width: 100%;
  }

  .adminorder-list li+li {
    margin-top: 20px;
    margin-left: 0;
  }

  .title-part {
    flex-wrap: wrap;
    justify-content: center;
  }

  .title-part h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }

  .back-btn {
    padding: 3px 15px;
    font-size: 12px;
    margin-right: 10px;
  }

  .action-btn,
  .add-remark-btn {
    padding: 3px 15px;
    font-size: 12px;
  }

  .orderinfo-header {
    flex-wrap: wrap;
    justify-content: center;
  }

  .orderinfo-header h2 {
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  .delivery-box h5 {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .content-sec {
    padding: 15px 11px 10px;
  }

  .filter-part>.btn {
    width: 80px;
  }

  .ordertittle-part>ul .form-group input {
    padding: 4px 30px 4px 5px;
  }

  .ordertittle-part>ul .form-group .search-icon {
    right: 4px;
  }

  .ordertittle-part>ul .form-group {
    width: 80%;
  }

  .dashboardcontent-title .track-btn {
    margin-left: 5px;
  }

  .dashboardcontent-title .ordertittle-part {
    flex-wrap: nowrap;
    margin-right: 15px;
  }


  .ordertittle-part {
    flex-wrap: wrap;
    position: relative;
  }

  .filter-part>.btn {
    width: 37px;
    font-size: 0;
  }

  .ordertittle-part h2 {
    /* text-align: center; */
    /* width: 100%; */
    margin-bottom: 20px;
    margin-right: 10px;
    font-size: 20px;
  }

  .ordertab-sec .nav-tabs {
    padding-right: 0;
  }

  .ordertab-sec .filter-part {
    top: -60px;
  }

  .ordertittle-part>ul {
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
  }

  .ordertittle-part>ul .add-item {
    margin-left: 0;
  }

  .ordertittle-part>ul .form-group {
    width: 100%;
  }

  .ordertittle-part>ul>li:first-child {
    width: calc(100% - 80px);
    position: absolute;
    top: 10px;
    left: 80px;
  }

  .ordertab-sec .nav-tabs .nav-link {
    padding: 9px;
    font-size: 14px;
    min-width: auto;
  }

  .statusbar-box p {
    font-size: 12px;
  }

  .trackingtable-tab .nav-pills .nav-link {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .adddpack-box {
    padding: 10px 5px 20px;
  }

  .status-text:after {
    top: 7px;
  }

  .customer-inner .ordertittle-part h2 {
    width: auto;
  }

  .customer-inner .ordertittle-part>ul {
    width: auto;
  }

  .customer-inner .ordertittle-part {
    padding-bottom: 10px;
  }

  .tab-content table td {
    padding: 10px 0;
  }

  .filterbtn-group button {
    padding: 7px 5px;
    font-size: 12px;
    display: block;
  }

  .settings-box h3 {
    font-size: 14px;
  }

  .settings-box li {
    width: calc(100%/2 - 10px);
    margin-right: 20px;
    margin-top: 20px;
  }

  .settings-box li:nth-child(3n+3) {
    margin-right: 20px;
  }

  .settings-box li:nth-child(2n+2) {
    margin-right: 0;
  }

  .userinfo-box h1 {
    padding: 15px;
    font-size: 16px;
  }

  .userinfo-body {
    padding: 15px;
  }

  .user-list li>span {
    width: 15px;
    height: 15px;
    border: none;
    top: -10px;
    left: 50%;
    margin-left: -8px;
  }

  .user-list li.active>span {
    border: none;
  }

  .user-list li {
    font-size: 10px;
    font-weight: 500;
    left: 0;
    text-align: left;
  }

  .user-list li>span:after {
    top: 0;
    left: 0;
  }

  .user-list:after {
    left: 8%;
    width: calc(100% - 16%);

  }

  .userprofile-list li {
    margin-right: 0px;
    width: calc(100%);
  }

  .userprofile-list li:nth-child(4n+4),
  .userprofile-list li:nth-child(3n+3) {
    margin-right: 0px;
  }

  .userprofile-list li:nth-child(2n+2) {
    margin-right: 0;
  }

  .settings-sec .ordertittle-part {
    /* justify-content: center; */
    align-items: center;
    flex-wrap: nowrap;
  }

  .settings-sec .ordertittle-part h2 {
    margin-bottom: 0;
    font-size: 16px;
  }

  .adminsetting-sec .title h2 {
    font-size: 20px;
    width: 100%;
    margin-left: 0;
  }

  .userinfo-body div {
    font-size: 14px;
  }

  .delivertype-list li>h2 {
    font-size: 18px;
  }

  .support-list>li {
    width: 100%;
  }

  .support-list>li:nth-child(2n+2) {
    margin-left: 0;
  }

  .sptitle {
    display: block;
  }

  .sptitle .select-box {
    /* max-width: 125px; */
  }

  .sptitle .select-box .form-select {
    max-width: calc(100% - 63px);
    padding-right: 0;
  }

  .sptitle .form-select {
    width: 100%;
  }

  .sptitle .select-box+.select-box {
    width: 100%;
  }

  .sptitle .select-box+.select-box {
    /* max-width: 220px; */
    margin-left: 0;
    margin-top: 10px;
  }

  .sptitle .select-box+.select-box .form-select {
    max-width: calc(100% - 70px);
  }

  #invoice-tab-pane ul li {
    width: calc(100%/2 - 13px);
    margin-top: 15px;
    font-size: 14px;
  }

  #cod-tab-pane>ul li {
    font-size: 14px;
  }

  #invoice-tab-pane ul {
    margin-top: 0;
  }

  #invoice-tab-pane ul li+li {
    margin-left: 0;
  }

  #invoice-tab-pane ul li:nth-child(3n+1) {
    margin-left: inherit;
  }

  #invoice-tab-pane ul li:nth-child(2n+2) {
    margin-left: 25px;
  }

  .invoice-tab .nav button {
    font-size: 14px;
    padding: 5px 10px;
  }

  .invoice-tab .tab-content ul li,
  .employe-table table td,
  .usersetting-table table td,
  .settings-sec .ordertittle-part button {
    font-size: 14px;
  }



  .cod-table table td {
    padding: 5px 10px 0;
  }

  .cod-table th {
    padding: 10px;
  }

  .notification-box .title .btn {
    font-size: 12px;
  }

  .notification-box .title h2,
  .callbuyerpopup h2 {
    font-size: 16px;
  }

  .callbuyer-box p {
    font-size: 14px;
  }

  .callbuyer-box {
    padding: 15px 0;
  }

  .userinfo-body label {
    font-size: 12px;
  }

  .shipment-box {
    display: block;
    margin: 10px 0 15px;
  }

  .shipment-box .shipment-btn {
    margin-top: 5px;
  }

  /* ==================userprofile-sec ============================== */


  .userprofile-sec .title-bar h2 {
    font-size: 20px;
    margin-right: 15px;
  }

  .userprofile-sec .title-bar .form-select {
    font-size: 16px;
  }

  .dashboardcontent-title h2 {
    font-size: 20px;
  }

  .trh-box {
    font-size: 14px;
  }

  .trh-box h4 {
    height: auto;
    font-size: 18px;
  }

  .trh-box {
    font-size: 12px;
  }

  /* .userprofile-sec .title-bar .left-part,
  .userprofile-sec .title-bar .right-part {
    width: 100%;
    text-align: center;
  } */

  .userprofile-sec .title-bar {
    flex-wrap: wrap;
  }

  .admin-toggle .toggle-icon {
    width: 25px;
    height: 25px;
  }

  .sptitle .select-box {
    margin-left: 0;
    width: 100%;
  }

  .userprofile-sec .title-bar .right-part {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .userprofile-sec .title-bar .right-part>span {
    width: 50%;
    margin-top: 10px;
    margin-right: 0;
  }
}


@media(max-width:515px) {
  .user-list:after {
    left: 10%;
    width: calc(100% - 19%);
  }

  .track-list:before {
    left: 23px;
  }

  .track-list {
    padding-left: 10px !important;
    padding-right: 10px;
  }

}

@media(max-width:400px) {

  .statusbtn-group .btn {
    width: 100%;
  }

  .statusbtn-group .btn:nth-child(2) {
    margin-left: 0;
    margin-top: 10px;
  }

  .react-tel-input .country-list {
    width: 230px !important;
    margin: 10px 0 10px -20px !important;
  }

  .callbuyerpopup {
    padding: 25px 10px;
  }

  .checkbox-mark {
    width: 10px;
    height: 10px;
  }

  .circle-checkbox {
    padding-left: 15px;
    font-size: 10px;
  }

  .admin-box .circle-checkbox input[type="checkbox"] {
    height: 0;
  }

  .feedback-popup .popup-body {
    padding: 10px;
  }

  .downloadinvoice-popup .popupinner {
    padding: 15px;
  }

  .downloadinvoice-popup .btngroups button.save-btn {
    margin-top: 25px;
  }

  .userprofile-sec .title-bar .right-part>span {
    width: 100%;
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 0px;
  }




}

.userinfo-body input:focus,
.userinfo-body select:focus,
.yes-btn:focus,
.no-btn:focus {
  border: 1px solid #FFC900 !important;
  box-shadow: none !important;
}

.editpopup input:focus,
.editpopup select:focus {
  border: 1px solid #FFC900 !important;

  box-shadow: none !important;
}

.multiSelectContainer>div>input {
  border: none !important
}

.css-lmndiq-5-menu {
  display: block !important;
}

.hoverclassBlock {
  display: block !important;
}

.hoverclassNone {
  display: none !important;

}

.css-lkh0o5-menu {
  background-color: white !important;
}

/* .css-lnmdiq5-Input{
  display: block !important;
}
.reacteeeee:hover{
  background-color: red;
}

.css-102slow-control{
  display: block !important;
} */

.colorHoverChange option:checked,
.colorHoverChange option:hover {
  background-color: rgb(237, 241, 21) !important;
}

/* .colorHoverChange>option{
  background-color: rgb(237, 241, 21) !important;
} */

.userinfo-body .react-tel-input .flag-dropdown,
.userinfo-body .react-tel-input .selected-flag,
.userinfo-body .react-tel-input .flag-dropdown.open,
.userinfo-body .react-tel-input .selected-flag {
  height: 32px !important;
  margin-top: 1px !important;
  margin-left: 1px !important;

}

.input_filed_block {
  color: #928e8e;
  cursor: not-allowed !important;
}